import React from 'react';

import {
  InputComponent,
  InputContainer
} from './styles';

import { AiOutlineMail } from "react-icons/ai"
import { FiLock } from "react-icons/fi"
import { IoIosSearch } from "react-icons/io"

import { IInputProps } from "../../@interfaces";

const Input: React.FC<IInputProps> = (props) => {
  return (
    <InputContainer style={props.containerStyle ? props.containerStyle : {}} GAPVertical={props.GAPVertical} colorOfBorder={props.colorOfBorder} animate={props.animate} hasBackground={props.hasBackground}>
      <InputComponent {...props} {...props.register && props.register()} />
      {props.isEmail && <AiOutlineMail size={props.sizeIcon ? props.sizeIcon : 18} color={props.colorOfBorder} />}
      {props.isPassword && <FiLock size={props.sizeIcon ? props.sizeIcon : 18} />}
      {props.isSearch && <IoIosSearch size={props.sizeIcon ? props.sizeIcon : 18}  style={{marginTop: 5}}/>}
    </InputContainer>
  );
}

export default Input;

