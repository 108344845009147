import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.8);
  z-index: 7878;
  top: 0;
  left: 0;
  
  .next{
    transform: translateX(20px);    
  }
  .previous{
    transform: translateX(-20px);    
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`


export const Content = styled.div`  
  margin: 0 auto;
  width: 80vw;
  height: 60%;
  transition: all .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  >img{
    max-width: 100%;
    max-height: 100%;
  }  
`

export const ContentImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  >img{
    max-width: 100%;
    max-height: 100%;
    transition: all 1s;
  }
`


const button = `
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`

export const ControllButtonRight = styled.button`
  position: absolute;
  top: 45%;
  right: 10px;
  ${button}
`

export const ControllButtonLeft = styled.button`
  position: absolute;
  top: 45%;
  left: 10px;
  ${button}  
`

export const MediaBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,80px);
  gap: 10px;
  margin-top: 10px;  

  >div{
    width: 80px;
    height: 80px;
    border: 1px dotted rgba(0,0,0,.3);
    cursor: pointer;
    >img{
      max-height: 100%;
      max-width: 100%;
    }
  }
  >span{
    font-size: .75rem;
    color:rgba(0,0,0,.3);
  }
`