import styled, { keyframes } from "styled-components"
import Button from "../button"

interface IButton {
  color?: string;
  noHover?: boolean
}

const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
`

export const RowSectionCSS = styled.div`
  display: flex;
  align-items: center;
`


export const ContentFilters = styled.div`
  display: flex;
  width: 100%;
  flex-direction:column;
  padding: 20px;
  .filterTitle{
    margin-bottom: 20px;
  }
`

export const ActionButton = styled(Button) <IButton>`
  width: 100px;
  margin-right: 10px;
  font-size: .75rem;
  ${({ color }) => {
    if (color) return `background:${color}`
  }}
    
`

export const Separator = styled.div`
  width: 100%;
  height: .2px;
  background: rgba(0,0,0,.1);
  margin: 20px 0 20px;
`

export const Content = styled.div`
  display: flex;
  flex: 1; 
  overflow-y: auto;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px 10px;
`

interface IProps {
  width?: number
}

export const ContentBox = styled.div<IProps>`
  transition: all .7s;
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
  animation: ${animaContent} .7s forwards;
  opacity: 0;
  width: ${props => props.width? props.width : 500}px;
  max-height: 500px; 
  background: ${props => props.theme.colors.white};
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  outline: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  >h1{
    font-weight: 200;
    font-size: 1rem;
  }

  >ul{
    margin-top: 20px;
    padding: 10px;
  }  

  @media (max-width: 1024px){
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
`


export const Header = styled.div`
  width: 100%;
  height: 40px;
  background: ${props => props.theme.colors.darkSilver};
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 0 10px;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  z-index: 90000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0,0,0,.7); 
`