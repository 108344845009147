import styled from "styled-components";

interface IProps {
  GAPVertical?: number;
  GAPHorizontal?: number;
  noBorder?: boolean;
  backgroundColor?: string;
  noBackgroundColor?: boolean;
}

export const SelectContainer = styled.div<IProps>`
  background-color: rgb(243, 246, 249);
  border: 1px solid rgb(229, 232, 236);
  cursor: pointer;
  padding: 5px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px;
  transition: all .7s;
  width: 100%;  
  border-bottom: .4px solid rgb(118, 118, 118);  
  color: rgb(118, 118, 118);
  &:focus-within{
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
  overflow: hidden;
  position:relative;
  >svg{
    position: absolute;
    right: 0; 
  }
  >span{
    font-size: .875rem;
    margin-right: 15px;
  }
  &:hover{
    background: #eee;
  }
  ${({ GAPVertical, noBackgroundColor }) => {

    if (GAPVertical) {
      return `margin: ${GAPVertical}px 0;`
    }
  }}

  ${({ noBorder }) => {
    if (noBorder) {
      return "border: none;"
    }
  }}

  ${({ backgroundColor }) => {
    if (backgroundColor) {
      return `background-color: ${backgroundColor};`
    }
  }}

  ${({ noBackgroundColor }) => {
    if (noBackgroundColor) {
      return `background: none;`
    }
  }}
`