import React, { cloneElement, useEffect, useState } from "react";

import {
  Container,
  TabList,
  TabName
} from "./styles"

import { IProps, ITab } from "./@types"


const Tab: React.FC<IProps> = (props) => {
  const [_tabs, setTabs] = useState<ITab[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    initComponent();
  }, [props]);


  function initComponent() {
    const aux = props.tabs.map((t, i) => {
      return {
        ...t,
        active: i === 0 ? t.active = true : false
      }
    });
    setActiveIndex(aux.length > 0 ? 0 : null)
    setTabs(aux);
  }


  function selectTab(index: number) {

    const aux = _tabs.map(t => {
      return {
        ...t,
        active: false
      }
    });
    aux[index].active = true;
    setTabs(aux);
    setActiveIndex(index);
  }


  return (
    <Container>
      <TabList>
        {
          _tabs?.map((i, index) => {
            return (
              <TabName active={i.active} key={index} onClick={() => selectTab(index)}>
                <h2>{i.name}</h2>
              </TabName>
            )
          })
        }
      </TabList>


      {
        props.tabs?.map((item, index) => {
          return(
            <div style={{ display: activeIndex === index ? 'block' : 'none' }} key={index}>
              {props.tabs[index].component}
            </div>
          )
        })
      }

    </Container>
  )
}

export default Tab;