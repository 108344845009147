import React from 'react';

import { MdKeyboardArrowDown } from "react-icons/md"


import {
  SelectContainer
} from './styles';

import { ISelectProps } from '../../@interfaces';


const Select: React.FC<ISelectProps> = (props) => {

  return (
    <>
      <SelectContainer
        GAPVertical={props.GAPVertical}
        onClick={props.onOpenOptions}

        noBackgroundColor={props.noBackgroundColor}
        noBorder={props.noBorder}
        backgroundColor={props.backgroundColor}
      >
        <span>{props.label}</span>
        <MdKeyboardArrowDown />
      </SelectContainer>
    </>
  )
}

export default Select;