import React, { useEffect, useState } from "react";

import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { cloneDeep } from "lodash"


import {
  TableContainer,
  RowContainer,
  Select,
  ControllContainer,
  ContainerPage,
  ButtonControll,
  ButtonControllContainer,
  TableData
} from "./styles"

import { IRows } from "../../@interfaces";


import { useTheme } from "styled-components";
import Loading from "../loading";





interface IColumns {
  fieldName: string;
  alignRight?: boolean;
}

interface IProps {
  columns: IColumns[];
  rolagemAzul?: boolean;
  rows?: IRows[];
  showNumberOfRowsChange?: boolean,
  actions?: Array<(id: number) => void>,
  refAction?: string
  rowsPerPage?: number
}




const Table: React.FC<IProps> = ({ columns, rows, showNumberOfRowsChange, actions, refAction, rowsPerPage, rolagemAzul }) => {
  const [_rows, setRows] = useState<IRows[]>([]);
  const [page, setPage] = useState<number>(1);
  const [lastPointer, setLastPointer] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0);
  const [REGISTER_PER_PAGE, setRegisterPerPage] = useState<number>(rowsPerPage||5);
  const [isThereAnyButtonAction, setIsThereAnyButtonAction] = useState<boolean>(false);
  const [colSpan, setColSpan] = useState<number>(0);

  const [actionLoading, setActionLoading] = useState<{row: number, action: number}| null >();

  const theme = useTheme();


  useEffect(() => {
    handleData();
  }, [rows, REGISTER_PER_PAGE])


  
  function handleData() {

    if (rows) {
      const _totalPages = Math.ceil(rows.length / REGISTER_PER_PAGE);
      setTotalPages(_totalPages);

      let isThereAny: boolean = false;

      for (let i = 0; i < rows.length; i++) {
        const { actionButtons } = rows[i];
        if (actionButtons && actionButtons.length > 0) {
          isThereAny = true;
          break;
        }
      }

      const data = rows?.slice(0, REGISTER_PER_PAGE);
      setIsThereAnyButtonAction(isThereAny);
      setRows(data);
      setPage(1);
      setLastPointer(0);

      setColSpan(isThereAny ? columns.length + 1 : columns.length)
    }
  }


  function next() {
    try {
      const pointer = lastPointer + REGISTER_PER_PAGE;
      const _rows = cloneDeep(rows);
      if (page < totalPages) {
        const data = _rows?.splice(pointer, REGISTER_PER_PAGE);
        if (data) {
          setRows(data);
          setPage(old => old + 1);
          setLastPointer(pointer);
        }
      }
    } catch (error) {
      throw new Error("Erro ao ir para próxima página: " + error);
    }
  }



  function previous() {
    try {
      const _rows = cloneDeep(rows);
      const pointer = lastPointer - REGISTER_PER_PAGE;

      if (page > 0) {
        const data = _rows?.splice(pointer, REGISTER_PER_PAGE);
        if (data) {
          setRows(data);
          setPage(old => old - 1);
          setLastPointer(pointer);
        }
      }
    } catch (error) {
      throw new Error("Erro ao ir para página anterior: " + error);
    }
  }



  function handleNumberOfRegister(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = parseInt(event.target.value);
    setRegisterPerPage(value);
  }


  async function actionClick(rowRefAction: number, indexAction: number) {
    setActionLoading({row: rowRefAction, action: indexAction});
    if (actions && rows && refAction) {
      await actions[indexAction](rowRefAction);
    }

    setActionLoading(null);
  }



  return (
    <>
      <TableContainer className={!!rolagemAzul ? 'rolagemAzul': ''}>
        <table style={{ tableLayout: 'fixed'}} >
          <thead>
            <tr>
              {
                columns?.map((column, index) => {
                  return <th key={index} style={{ textAlign: column.alignRight ? 'right' : 'left' }} >
                    {column.fieldName}
                  </th>
                })
              }

              {isThereAnyButtonAction ? <th /> : null}
            </tr>
          </thead>
          <tbody>
            {
              _rows?.map((row, index) => {
                const { actionButtons } = row;

                return (
                  <tr key={index}>
                    {columns.map((c, indexColumn) => {
                      if (index !== columns.length) {
                        return <TableData key={indexColumn} alignRight={c.alignRight}>

                          {row[c.fieldName]}

                        </TableData>
                      }
                    })}

                    {
                      (actionButtons && actionButtons.length > 0) && refAction ? (
                        <TableData>
                          <RowContainer>
                            {
                              actionButtons.map((eb, indexEB) => {
                                return actionLoading?.row === row[refAction] && actionLoading?.action === eb.indexAction 
                                ? <Loading width={15} height={15} noPadding compact/> : (
                                  <a onClick={() => actionClick(row[refAction], eb.indexAction)} id={"actionButton"} key={indexEB} target="_blank">
                                    {eb.icon}
                                  </a>
                                )
                              })
                            }
                          </RowContainer>
                        </TableData>
                      ) : isThereAnyButtonAction ? <td /> : null
                    }

                  </tr>
                )
              })
            }


            <tr>
              <td colSpan={colSpan}>
                {rows && rows.length > 0 && <ControllContainer>
                  <ContainerPage>
                    <span>{`Página ${page}/${totalPages}`}</span>
                  </ContainerPage>

                  {showNumberOfRowsChange && <ContainerPage style={{ display: "flex" }}>
                    <Select onChange={(e) => handleNumberOfRegister(e)}>
                      <option value={5}>5/pag.</option>
                      <option value={10}>10/pag.</option>
                      <option value={15}>15/pag.</option>
                      <option value={20}>20/pag.</option>
                      <option value={30}>30/pag.</option>
                      <option value={40}>40/pag.</option>
                    </Select>
                  </ContainerPage>}

                  {!((page === 1) && (page === totalPages)) && <ButtonControllContainer>
                    <ButtonControll disabled={page === 1} onClick={previous}>
                      <MdKeyboardArrowLeft color={theme.colors.white} size={20} />
                    </ButtonControll>
                    <ButtonControll disabled={page === totalPages} onClick={next}>
                      <MdKeyboardArrowRight color={theme.colors.white} />
                    </ButtonControll>
                  </ButtonControllContainer>}
                </ControllContainer>}

              </td>
            </tr>

          </tbody>


        </table>

      </TableContainer>

    </>
  )
}

export default Table
