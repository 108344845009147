import styled from "styled-components"

export const Container = styled.div`
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  padding-top: 20px;

  >span{
    font-size: .875rem;

  }
`

export const Ul = styled.ul`  
  >li:nth-child(odd){
    background: #fff;
  }
  margin-top: 20px;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
`