import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top : 0;
  left: 0;
  z-index: 5555;
  background: rgba(0,0,0,.6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 20px;
`

export const PDFContainer = styled.div`
  width: 100%;
  height: 100%; 

  overflow: hidden;
  position: relative;
  iframe {
    border: none;
  }
`