import styled from "styled-components"


export const Container = styled.div<{ aDireita?: boolean }>` 
  position: relative;  
  cursor: pointer;
  >div{
    border-radius: 4px;
    display: none;
    background-color: rgba(0,0,0,.8);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    position: absolute;
    z-index: 99999;
    top: 10px;
    ${props => props.aDireita ? `right: 110%;` : `left: 90%;`}
    transition: all .7s;
    text-align: center;
    padding: 10px;
    min-width: 120px;
  }
  :hover div{
    transition: all .7s;
    display: inline-block;
   
  }
`
export const Tip = styled.span` 
  line-height: 20px;
  color: #fff;
  font-size: .785rem;    
`