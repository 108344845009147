import React, { useEffect, useMemo, useRef, useState, memo } from 'react';
import { Card, Header, Content, ContentImage, CarouselContainer, ImageSlide, BoxImage, ContainerPdfs } from './styles';
import postImage from "./postImage.png"
import Linkify from "linkify-react"
import { ICardNoticiaProps } from '../../../@interfaces';


import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Doc from './doc';
import ImageView from '../../../components/imageview';
import moment from 'moment';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,

  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,

  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,

  }
};


const CardFeed: React.FC<ICardNoticiaProps> = (props) => {
  const [openImageView, setOpenImageView] = useState<{ show: boolean, img: string }>({ show: false, img: '' });
  const [visibleSaibaMais, setVisibleSaibaMais] = useState<boolean>(false);
  const [changeVisibleMais, setChangeVisibleMais] = useState(false)


  const pdfIframe = useMemo(() => {
    if (!props.pdfs) return null;


    if (props.pdfs.length === 1) {
      return (
        <ContainerPdfs>
          <h2>Documentos</h2>
          <Doc name={`${props.pdfs[0].name}`} fileBase64={props.pdfs[0].pdf} />
        </ContainerPdfs>
      );
    } else if (props.pdfs.length > 1) {
      return (
        <ContainerPdfs>
          <h2>Documentos</h2>
          {props.pdfs.map(i => <Doc name={`${i.name}`} fileBase64={i.pdf} />)}
        </ContainerPdfs>
      )
    }
    return null;
  }, [props.pdfs]);


  const date = useMemo(() => {
    return moment(props.dateCreated.replace('Z', '')).format('DD/MM/YYYY HH:mm')
  }, [props.dateCreated])


  const bodyNoticia = useMemo(() => {
    if (props.body) {
      let body = null;

      if ((props.body.length > 230) && !visibleSaibaMais) {
        body = props.body.substring(0, 230) + ' ...';
        setVisibleSaibaMais(true);
      } else {
        body = props.body;
      }
      const splited = body.trim().split('\r\n');
      let response = splited.map((i, index) => {
        return <div key={index} style={{ opacity: !i ? 0 : 1 }}>{!i ? '.' : i}</div>
      })
      if (visibleSaibaMais) setVisibleSaibaMais(false);
      return response;
    }
    else {
      return '';
    }
  }, [props.body, changeVisibleMais])




  const imagesMemo = useMemo(() => {
    if (!props.imageBase64) return null;
    if (props.imageBase64.length === 1) {
      return (
        <ContentImage onClick={() => openImage(props.imageBase64![0].image)}>
          <img src={props.imageBase64[0].image} style={{ maxWidth: '100%' }} />
        </ContentImage>
      )
    } else if (props.imageBase64.length > 1) {
      return (
        <CarouselContainer>
          <h2>Imagens</h2>
          {/**@ts-ignore */}
          <Carousel responsive={responsive} itemClass='itemCarousel' containerClass='containerCarousel' infinite>
            {
              props.imageBase64.map((item) => {
                return (
                  <BoxImage onClick={() => openImage(item.image)}>
                    <ImageSlide src={item.image} />
                  </BoxImage>
                )
              })
            }
          </Carousel>
        </CarouselContainer>
      )
    }

  }, [props.imageBase64])



  function openImage(base64: string) {
    setOpenImageView({ show: true, img: base64 })
  }

  function closeImage() {
    setOpenImageView({ show: false, img: '' })
  }


  function showMoreMessage() {
    setChangeVisibleMais(old => !old);
  }




  return (
    <>
      <ImageView imageBase64={openImageView.img} close={closeImage} />
      <Card>
        <Header>
          <h1>{props.title}</h1>
          <span>{date}</span>
        </Header>
        <Content hasImage={props.imageBase64 && props.imageBase64?.length > 0 ? true : false}>
          {bodyNoticia && <Linkify as="p" options={{ className: 'linkIntoText', target: '_blank' }}>
            {bodyNoticia}
          </Linkify>}
          {visibleSaibaMais && <span className='btn_visibleMais' onClick={showMoreMessage}>Ver mais</span>}
        </Content>
        {imagesMemo}
        {pdfIframe}
      </Card>
    </>
  )
}

export default memo(CardFeed);
