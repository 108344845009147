import React, { useContext } from "react";
import Tab from "../../components/tab"
import Faturas from "./faturas"
import Boletos from "./boletos"

import {
  Container
} from "./styles";


import { IRows } from "../../@interfaces";
import APIFinanceiro from "../../services/APIFinanceiro";


const Financeiro: React.FC = () => {

  async function getFile(arquivoId: number){
    try {
      const response = await APIFinanceiro.getArquivoDownload({ Arquivos: [arquivoId]});

      if(!response.error && response.listaArquivo) {
        return { base64: response.listaArquivo[0].conteudo};
      }else{
        return { errorMessage: response.message };
      }

    }catch{
      return { errorMessage: "Algo deu errado"};
    }
  }


  return (
    <Container>
      <Tab
        tabs={[
          {
            name: "Faturas",
            component: <Faturas getFile={getFile} />
          },
          {
            name: "Boletos",
            component: <Boletos getBoletoFile={getFile}/>
          },
        ]}
      />
    </Container>
  )
}

export default Financeiro;