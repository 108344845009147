import { ButtonHTMLAttributes } from "react";
import { Botao } from "./styles"
import { IButtonProps } from "../../@interfaces";

const BotaoFiltrar: React.FC<IButtonProps> = (props) => {
  return (
    <Botao {...props}>
      Buscar
    </Botao>
  )
}

export default BotaoFiltrar;