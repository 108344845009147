import React, { useContext } from "react";
import MenuContext from "../../contexts/menu";
import {
  Container,
  Overlay
} from "./styles"

const Content: React.FC = ({ children }) => {
  const { isOpen, toggleMenu } = useContext(MenuContext);
  const isMenuOpen = isOpen;

  return (
    <Container id="content">
      {isMenuOpen && <Overlay onClick={toggleMenu} />}
      {children}
    </Container>
  )
}

export default Content;