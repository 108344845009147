import styled, { ThemeConsumer } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const DateFilterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  font-size: .9rem;
`

export const FilterLink = styled.a`
  margin-top: 3px;
  :hover{
    cursor: pointer;
  }
`

export const EmptyListMessage = styled.span`
  align-self: center;
  letter-spacing: 1px;
`

export const LoadingOverlay = styled.div`
  background: rgba(0,0,0,.1);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 10px;
`


export const CompanyFilterContainer = styled.div`
  display: flex;
  gap: 10px;
`

interface ICompanyFilterProps {
  selected?: boolean
}

export const CompanyFilter = styled.button<ICompanyFilterProps>`
  border: none;
  background: ${({ selected, theme }) => selected ? theme.colors.darkSilver: theme.colors.white};
  color: ${({ selected, theme }) => selected ? theme.colors.white : theme.colors.darkSilver};
  ${({ selected, theme }) => !selected && `box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`}
  padding: 10px;
  border-radius: 12px;

  :hover{
    cursor: pointer;
  }
`