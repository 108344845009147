import { InputBox, Container } from "./styles";

import { ICheckBoxProps } from "../../@interfaces";

const Checkbox: React.FC<ICheckBoxProps> = ({ gapVertical, label, onCheck, margin, checked }) => {
  return (
    <Container gapVertical={gapVertical} margin={margin}>
      <InputBox type="checkbox" onChange={onCheck} checked={checked} />
      <label >{label}</label>
    </Container>
  )
}

export default Checkbox;