import axios from './APIAxios';
import storage from '../storage';


import { IFuncionariosListBody, IFuncionariosListResponse } from '../@interfaces';


const ApiGeral = {
  getFuncionariosList: async (body: IFuncionariosListBody): Promise<IFuncionariosListResponse> => {
    const url = storage.getClientUrl();
    try {
      const response = await axios.post<IFuncionariosListResponse>(`${url}/ListaFuncionarios`, body)

      if (response.data.status === 0) {
        return response.data
      }
      return { error: true, message: response.data.mensagem };
    } catch (e) {
      return { error: true, message: e };
    }
  },
}

export default ApiGeral;