import { createContext, useContext, useEffect } from "react";
import { BsChevronCompactDown, BsFillArrowDownCircleFill } from "react-icons/bs";
import { IDocumento } from "../../@interfaces";
import { Filtros } from "../../@types";
import { ButtonSearch } from "../../pages/funcionarios/styles";
import DocView from "../docview";
import Loading from "../loading";
import MensagemListaVazia from "../mensagemlistavazia";
import Options from "../select/options";
import DocItem from "./docItem";
import { BotaoEmpresa, CheckLabel, CheckOption, Container, ContainerSelecao, DocList, DownloadSelectedFilesButton, DropDown, EmpresasContainer, FilterContainer, InputContainer, InputDate, LinhaSkeleton, TituloAgrupamento } from "./styles";
import useDocs, { IDocsProps } from "./useDocs";
import PropsProvider, { PropsContext } from "./props";
import BotaoFiltrar from "../botaofiltrar";
import Skeleton from "react-loading-skeleton";




const Docs: React.FC<IDocsProps> = (props) => {

  const {
    docFuncionario,
    filtrosAtuais,
    filtroAberto,
    colors,
    todosSelecionados,
    carregando,
    acaoCarregando,
    documentoAberto,
    modalDocumentoAberto,
    empresas,
    locais,
    empresasDisponiveis,
    empresaSelecionada,
    alocacoes,
    statusAssinatura,
    documentosSelecionados,
    tiposDocumento,
    tipoSelecionado,
    concantenarNome,
    mostrarLista,
    listaAgrupada,
    opcoes,
    buscaDisponivel,
    carregarDocumentos,
    selecionarEmpresa,
    abrirDocumento,
    fecharDocumento,
    baixarArquivo,
    baixarArquivosSelecionados,
    atualizarValoresFiltros,
    abrirOpcoes,
    fecharOpcoes,
    marcarDocumento,
    marcarTodosDocumentos,

  } = useDocs(props);


  function Placeholder(){
    return(
      <>
      <Skeleton height={20} width={'20%'} style={{ marginBottom: 30}}/>
  
        {Array(1).fill(0).map((item, index) => (
          <>
          <Skeleton height={20}  width={'10%'} key={index}/>
          <LinhaSkeleton key={index}>
            {Array(4).fill(0).map((item, index) => <Skeleton height={40} key={index}/>)}
          </LinhaSkeleton>
          </>
        ))}
      </>
    )
  }

  
  return (
    <Container paddingLateral={docFuncionario ? 0 : 20}>

      {modalDocumentoAberto && <DocView file={documentoAberto} closeView={fecharDocumento} />}

      {!!filtroAberto &&
        <Options close={fecharOpcoes} selectOption={valor => atualizarValoresFiltros(valor, filtroAberto)} itens={opcoes[filtroAberto]} />}


      <>
        {!!tiposDocumento &&
          <FilterContainer paddingLateral={docFuncionario ? 10: 0}>
            {/* <a onClick={openFilterModal}>
              <FaFilter color={colors.halfOpacityBlack} style={{ marginTop: 3 }} />
              <label>Filtros</label>
            </a> */}

            <InputContainer>
              <label>Tipo de documento</label>
              <DropDown onClick={() => abrirOpcoes('tipoDocumento')}>
                <span>
                  {filtrosAtuais?.tipoDocumento !== null ? tipoSelecionado?.label : 'Escolha o tipo de documento' }
                </span>
                <BsChevronCompactDown />
              </DropDown>

            </InputContainer>


            <InputContainer>
              <label>{docFuncionario ? "Período" : "Ano e mês"}</label>
              <div className="row">
                <InputDate
                  type={docFuncionario ? "date" : "month"}
                  onChange={e => atualizarValoresFiltros(e.target.value, 'dataInicial')}
                  max={docFuncionario ? filtrosAtuais?.dataFinal || "9999-12-31" : "9999-12"}
                  value={filtrosAtuais?.dataInicial}
                  disabled={carregando}
                />
                {docFuncionario && <><span>-</span>
                  <InputDate
                    type={'date'}
                    value={filtrosAtuais?.dataFinal}
                    onChange={event => atualizarValoresFiltros(event.target.value, 'dataFinal')}
                    max={"9999-12-31"}
                    min={filtrosAtuais?.dataInicial}
                    disabled={carregando}
                  />
                </>}
              </div>
            </InputContainer>



            {!docFuncionario && !!tipoSelecionado && tipoSelecionado.value !== 0 &&
              <InputContainer>
                <span>Empresa</span>
                <DropDown onClick={() => abrirOpcoes('empresa')}>
                  <span>{!!filtrosAtuais?.empresa ? empresas?.find(item => item.value === filtrosAtuais.empresa)?.label : "Todas"}</span>
                  <BsChevronCompactDown />
                </DropDown>
              </InputContainer>
            }


            {tipoSelecionado?.grupoFiltros === 'cliente' &&
              <InputContainer>
                <label>Local</label>
                <DropDown onClick={() => abrirOpcoes('local')}>
                  <span>{!!filtrosAtuais?.local ? locais?.find(item => item.value === filtrosAtuais.local)?.label : "Todos"}</span>
                  <BsChevronCompactDown />
                </DropDown>
              </InputContainer>
            }


            {filtrosAtuais?.tipoDocumento === 1 && docFuncionario &&
              <>
                <InputContainer>
                  <label>Tipo de alocação</label>
                  <DropDown onClick={() => abrirOpcoes('alocacao')}>
                    <span>{!!filtrosAtuais?.alocacao ? alocacoes?.find(item => item.value === filtrosAtuais.alocacao)?.label : "Todos"}</span>
                    <BsChevronCompactDown />
                  </DropDown>
                </InputContainer>

                <InputContainer>
                  <label>Situação de Assinatura</label>
                  <DropDown onClick={() => abrirOpcoes('assinatura')}>
                    <span>{!!filtrosAtuais?.assinatura ? statusAssinatura?.find(item => item.value === filtrosAtuais.assinatura)?.label : "Todos"}</span>
                    <BsChevronCompactDown />
                  </DropDown>
                </InputContainer>
              </>
            }


            {buscaDisponivel && <BotaoFiltrar loading={carregando} onClick={carregarDocumentos}>Buscar</BotaoFiltrar>}


          </FilterContainer>
        }


        <EmpresasContainer>
          {empresasDisponiveis?.map(item =>
            <BotaoEmpresa selecionado={empresaSelecionada === item.empresa} onClick={() => selecionarEmpresa(item.empresa)}>
              {item.nome}
            </BotaoEmpresa>
          )}
        </EmpresasContainer>
      </>

      {!carregando && mostrarLista &&
        <>
          <ContainerSelecao selecionarTodos={todosSelecionados}>
            <CheckOption>
              <input
                type={"checkbox"}
                id="select_all"
                checked={todosSelecionados}
                onChange={event => marcarTodosDocumentos(event.target.checked)}
              />
              <CheckLabel
                htmlFor="select_all"
                style={{ color: todosSelecionados ? colors.white : colors.halfOpacityBlack }}
              >
                Selecionar todos
              </CheckLabel>
            </CheckOption>

            {documentosSelecionados.length > 0 && <DownloadSelectedFilesButton onClick={baixarArquivosSelecionados}>
              <BsFillArrowDownCircleFill size={16} color={todosSelecionados ? colors.white : colors.halfOpacityBlack} style={{ marginBottom: 2 }} />
              <span style={{ color: todosSelecionados ? colors.white : colors.halfOpacityBlack }}>Baixar documentos selecionados</span>
            </DownloadSelectedFilesButton>}

          </ContainerSelecao>
          <DocList>

            {listaAgrupada!.map(item => (
              <>
                <TituloAgrupamento>{item.tipo}</TituloAgrupamento>
                <ul style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  {item.documentos.map((documento: IDocumento) => (
                    <DocItem
                      documento={documento}
                      documentosSelecionados={documentosSelecionados}
                      concatenarNome={concantenarNome}
                      selecionarDocumento={marcarDocumento}
                      abrirDocumento={abrirDocumento}
                      baixarArquivo={baixarArquivo}
                      acaoCarregando={acaoCarregando}
                    />
                  ))}
                </ul>
              </>
            ))}

          </DocList>
        </>}


      {!mostrarLista && !carregando &&
        <MensagemListaVazia
          texto={'Filtre para buscar documentos'}
          textoSecundario={'Não há dados a serem mostrados'}
        />}



      {carregando && <Placeholder />}

    </Container>
  )
}



export default Docs;