import React, { useEffect, useState } from 'react';


import ReactAudioPlayer from 'react-audio-player';

import LightBoxGallery from '../../../components/lightboxgallery';
import CloseButton from '../../../components/closebutton';
import APIRondas from '../../../services/APIRondas';
import {
  Container,
  SectionTop,
  Section,
  Title,
  Value,
  Separator,
  ContainerPath,
  Content
} from './styles';
import { IOcorrencia } from '../../../@interfaces';
import moment from 'moment';
import { useTheme } from 'styled-components';

const audio = ''

interface IProps {
  ocorrencia: IOcorrencia,
  close: () => void;
  show: boolean
}


interface IImages {
  src: string;
}


const OcorrenciaDetalhe: React.FC<IProps> = ({ ocorrencia, close, show }) => {

  const [images, setImages] = useState<IImages[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [audios, setAudios] = useState<string[]>([])

  const theme = useTheme();


  useEffect(() => {
    if (show) getAnexos();
    else {
      setImages([]);
      setAudios([])
    }
  }, [show, ocorrencia])




  async function getAnexos() {
    try {
      setLoading(true);

      const { listaArquivos } = ocorrencia;
      if (listaArquivos && listaArquivos.length > 0) {
        const response = await APIRondas.getAnexosOcorrencia({ Arquivos: listaArquivos });
        if (!response.error) {
          const _audios: string[] = [];
          const _imgs: any = [];

          if (response.listaArquivo && response.listaArquivo.length > 0) {
            const { listaArquivo } = response;

            for (const item of listaArquivo) {
              const conteudo = item.conteudo.split(`,`)[1]
              if (!detectMimeType(conteudo)) {
                _audios.push(conteudo);
              } else {
                _imgs.push({ src: item.conteudo });
              }
            }

          }
          setImages(_imgs);
          setAudios(_audios);
        }
      }

      setLoading(false)
    } catch (error) {
      setLoading(false);
      throw new Error("Erro ao recuperar anexos: " + error)
    }
  }


  function detectMimeType(b64: string) {
    const signatures: any = {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg"
    };
    for (const s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }


  if (!show) return null

  return (
    <>
      <Container>
        <ContainerPath>
          <h2 id="titleOcorrencia">Ocorrência #{ocorrencia?.ocorrencia}</h2>
          <CloseButton handleClose={close} color={theme.colors.white} size={22} />
        </ContainerPath>
        <Content>
          <SectionTop>
            <div>
              <Title>Data da ocorrência:</Title>
              <Value>{moment(ocorrencia?.dataOcorrencia.replace("Z", "")).format("DD/MM/YYYY")}</Value>
            </div>

            <div>
              <Title>Motivo:</Title>
              <Value>{ocorrencia?.motivo}</Value>
            </div>

            <div>
              <Title>Situação:</Title>
              <Value>{ocorrencia?.situacao}</Value>
            </div>

            <div>
              <Title>Local:</Title>
              <Value>{ocorrencia?.localServico}</Value>
            </div>
          </SectionTop>

          <Separator />

          <Section>
            {ocorrencia.descricaoOcorrencia && <div>
              <Title>Descrição do Colaborador</Title>
              <Value>
                {ocorrencia?.descricaoOcorrencia}
              </Value>
            </div>}


            {ocorrencia.descricaoEmpresa && <div style={{ marginTop: 30 }}>
              <Title>Descrição da Empresa</Title>
              <Value>{ocorrencia?.descricaoEmpresa}</Value>
            </div>}
          </Section>

          <Separator />




          <Section>
            <Title>Anexos</Title>
            {loading && <span id="loading">Carregando anexos ...</span>}
            {!loading && (images.length === 0 && audios.length === 0) && <span id="loading">Ocorrência sem anexo</span>}
            <LightBoxGallery data={images} />
            {
              audios?.map(i => {
                return <ReactAudioPlayer src={`data:audio/mp4;base64,${i}`} controls />
              })
            }
          </Section>
        </Content>
      </Container>
    </>
  )
}

export default OcorrenciaDetalhe;