import axios from './APIAxios';
import storage from '../storage';


import { INewUser, IUpdatedUser, ICustumers, ITrocaDeSenhaResponse, ICustumersResponse } from '../@interfaces';
import { PasswordChange } from "../@types"

const APIUser = {
  getUserList: async function () {
    const url = storage.getClientUrl();

    try {
      const response = await axios.get(`${url}/ListaUsuarios`)
      if (response.data.status !== 0) {
        return { err: true, message: response.data.mensagem }
      }

      return response.data.usuarios;


    } catch {
      return { err: true, message: 'Algo deu errado' };

    }

  },

  getClientList: async function (): Promise<ICustumersResponse> {
    const url = storage.getClientUrl();
    try {
      const response = await axios.get(`${url}/ListaClientes`)

      if (response.data.status !== 0) {
        return { error: true, message: response.data.mensagem, status: response.data.status }
      }

      return response.data;

    } catch (error) {
      return { error: true, message: "Ops... Algo deu errado: " + error }
    }
  },

  addNewUser: async function (body: INewUser) {
    const url = storage.getClientUrl();
    try {
      const response = await axios.post(`${url}/IncluirUsuario`, body);
      if (response.data.status !== 0) {
        return { err: true, message: response.data.mensagem }
      }

      return { err: false, message: 'Usuário cadastrado com sucesso.' }
    } catch (e) {
      return { err: true, message: 'Algo deu errado' };
    }
  },

  updateUser: async function (body: IUpdatedUser) {
    const url = storage.getClientUrl();
    try {
      const response = await axios.post(`${url}/AlterarUsuario`, body);

      if (response.data.status !== 0) {
        return { err: true, message: response.data.mensagem }
      }

      return { err: false, message: 'Usuário cadastrado com sucesso.' }
    } catch (e) {
      return { err: true, message: 'Algo deu errado' };
    }
  },

  deleteUser: async function (id: string | number) {
    const url = storage.getClientUrl();
    const body = {
      usuario: id
    }
    try {
      const response = await axios.post(`${url}/ExcluirUsuario`, body);
      if (response.data.status !== 0) {
        return { err: true, message: response.data.mensagem }
      }

      return { err: false, message: 'Usuário cadastrado com sucesso.' }
    } catch (e) {
      return { err: true, message: 'Algo deu errado' };
    }
  },

  changePasswordIntoApplication: async (body: PasswordChange): Promise<ITrocaDeSenhaResponse> => {
    const url = storage.getClientUrl();
    try {
      const response = await axios.post(`${url}/TrocarSenha`, body)
      if (response.data.status === 0) {
        return response.data
      }
      return { error: true, message: response.data.mensagem };
    } catch (e) {
      return { error: true, message: e };
    }
  },
}

export default APIUser;