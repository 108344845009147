import { useContext, useEffect, useState } from "react";
import { Produto, SelectOptions } from "../../@types";
import BotaoFiltrar from "../../components/botaofiltrar";
import FiltroInput from "../../components/filtroinput";
import { Container, HeaderRotulo, HeaderValor, LinhaFiltros, LinhaSkeleton, MensagemContainer, QuadroHeader, Tabela } from "./styles";
import APIQuadroVagas from "../../services/APIQuadroVagas";
import APIPonto from "../../services/APIPonto";
import { CustumerContext } from "../../contexts/custumer";
import { ILocal } from "../../@interfaces";
import Options from "../../components/select/options";
import ToastContext from "../../contexts/toast";

import Skeleton from "react-loading-skeleton";
import { useQuadroDeVagas } from "./useQuadroDeVagas";
import aux from "../../utils/auxiliar";
import MensagemListaVazia from "../../components/mensagemlistavazia";
import moment from "moment";
import Loading from "../../components/loading";
import Button from "../../components/button";




const QuadrodeVagas: React.FC = () => {
  const  {
    quadro,
    locais,
    filtros,
    opcaoDeFiltroAberta,
    carregandoQuadro,
    carregandoLocais,
    carregarQuadro,
    abrirDropdown,
    fecharDropdown,
    selecionarFiltro,
    opcoesOrdem,
    dataInvalida
  } = useQuadroDeVagas();


  function Placeholder(){
    return(
      <>
      <Skeleton height={40} width={'100%'} style={{ marginBottom: 30}}/>
  
        {Array(4).fill(0).map((item, index) => (
          <LinhaSkeleton key={index}>
              {Array(7).fill(0).map((item, index) => <Skeleton height={20} key={index}/>)}
          </LinhaSkeleton>
        ))}
      </>
    )
  }
  
  const COLS_SPAN_MAIOR = 3;
  const COLS_SPAN_MEDIO = 2;
  const COLS_SPAN_PEQUENO = 1.5;


  const semDadosParaExibir = !quadro || quadro.length === 0|| (quadro.length === 1 && quadro[0].produtos.length === 0 );
  return(
    <Container>


      {!!opcaoDeFiltroAberta &&
        <Options
          itens={opcaoDeFiltroAberta === 'local'? locais : opcoesOrdem}
          close={fecharDropdown}
          selectOption={(valor) => selecionarFiltro(opcaoDeFiltroAberta, valor)} 
          sortAlphabetically
        /> 
      }
    
      {carregandoLocais && <Loading />}

      {!carregandoLocais && <LinhaFiltros>
        

        <div>
          <span>
            Período <span style={{color: 'rgba(0,0,0,.5)', fontSize: 12}}>(máx. 30 dias)</span> 
          </span>

          <FiltroInput 
            tipo="data" 
            onChange={e => selecionarFiltro('dataInicio', e.target.value)} 
            value={filtros.dataInicio} 
            max={"9999-12-31"} 
            data-testid="data_inicial" 
            erro={dataInvalida}
          />
          -
          <FiltroInput 
            tipo="data" 
            onChange={e => selecionarFiltro('dataFinal', e.target.value)} 
            value={filtros.dataFinal} 
            max={"9999-12-31"} 
            data-testid="data_final" 
            erro={dataInvalida}
          />

        </div>


        <div>
          <span>
            Ordem
          </span>

          <FiltroInput tipo="dropdown" abrirDropdown={() => abrirDropdown("ordem")}>
            <span>{opcoesOrdem.find(item => item.value === filtros?.ordem)?.label}</span>
          </FiltroInput>

        </div>

        <div>
          <label>
            Local
          </label>

          <FiltroInput tipo="dropdown" abrirDropdown={() => abrirDropdown("local")}>
            <span>{locais?.find(item => item.value === filtros.local)?.label}</span>
          </FiltroInput>

        </div>

        {filtros.dataInicio && filtros.dataFinal && <BotaoFiltrar onClick={carregarQuadro} loading={carregandoQuadro}/>}

      </LinhaFiltros>}
      
      {carregandoQuadro && <Placeholder />}  
        
      {!carregandoQuadro && quadro?.map((local, index) => (
        
        <div key={local.iDLocal}>
          {quadro.length > 1 && <h1 style={{ margin: '30px 0 20px'}}>{local.nomeLocal}</h1>}

          {local.produtos.map(produto => (
            <div key={produto.produto} style={{ position: 'relative' }}>  
              <QuadroHeader>
                <HeaderRotulo>Produto: </HeaderRotulo>
                <HeaderValor>{produto.produto}</HeaderValor>
                
                <HeaderRotulo>Escala: </HeaderRotulo>
                <HeaderValor>{produto.escala}</HeaderValor>
                
                <HeaderRotulo>Cargo: </HeaderRotulo>
                <HeaderValor>{produto.cargo}</HeaderValor>
                
                <HeaderRotulo>Turno: </HeaderRotulo>
                <HeaderValor>{produto.turno}</HeaderValor>
              </QuadroHeader>

              {produto.vagas?.length! > 0 && <Tabela>
                <thead>
                  <tr>
                    <th>Vaga</th>
                    <th>Data</th>
                    <th>Situação</th>
                    <th colSpan={COLS_SPAN_PEQUENO}>Tipo</th>
                    <th colSpan={COLS_SPAN_MAIOR}>Efetivo</th>
                    <th colSpan={COLS_SPAN_MEDIO}>Horário Vaga</th>
                    <th colSpan={COLS_SPAN_PEQUENO}>Situação</th>
                    <th colSpan={COLS_SPAN_MAIOR}>Cobertura</th>
                    <th colSpan={COLS_SPAN_MEDIO}>Horário Ponto</th>
                  </tr>
                </thead>

                <tbody>
                {produto.vagas?.map(item => (
                  <tr key={item.vaga}>
                    <td>{item.vaga}</td>
                    <td>{moment(item.data).utc().format('DD/MM/YYYY')}</td>
                    <td>{item.situacao}</td>
                    <td colSpan={COLS_SPAN_PEQUENO}>{item.origemVaga}</td>
                    <td colSpan={COLS_SPAN_MAIOR}>{item.efetivo ? `${item.efetivo.re} - ${item.efetivo.nome}`: '-'}</td>
                    <td colSpan={COLS_SPAN_MEDIO}>{item.horarioVaga ? `${item.horarioVaga.entrada}  ${item.horarioVaga.saidaRefeicao} ${item.horarioVaga.retornoRefeicao} ${item.horarioVaga.saida}`:'-'} </td>
                    <td colSpan={COLS_SPAN_PEQUENO}>{item.situacaoCobertura}</td>
                    <td colSpan={COLS_SPAN_MAIOR}>{item.cobertura ? `${item.cobertura.re} - ${item.cobertura.nome}`: '-'}</td>
                    <td colSpan={COLS_SPAN_MEDIO}>{item.horarioPonto ? `${item.horarioPonto.entrada}  ${item.horarioPonto.saidaRefeicao} ${item.horarioPonto.retornoRefeicao} ${item.horarioPonto.saida}`:'-'} </td>


                  </tr>
                ))}

                </tbody>

              </Tabela>}

              {produto.vagas?.length === 0 && <MensagemContainer> <span > Não há vagas a serem exibidas</span></MensagemContainer>}

            </div>
          ))}

        </div>
      ))}

      
      {semDadosParaExibir && !carregandoQuadro && !carregandoLocais && <MensagemListaVazia texto="Não há produtos a serem exibidos" textoSecundario={"Filtre para buscar produtos"}/>}
    </Container>
  )
}


export default QuadrodeVagas;