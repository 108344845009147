import styled from "styled-components";


interface IProps {
  strColor?: string;
}

export const Container = styled.div<IProps>`
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 140px;
  padding: 6px;
  position: relative;
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,.2);
  margin-right: 10px;

  ${({ strColor }) => {
    if (strColor) {
      return `color:${strColor}`
    }
  }}

  >input{
    border: none;
    outline: none;
    ${({ strColor }) => {
    if (strColor) {
      return `color:${strColor}`
    }
  }}
  }
  >label{
    position:absolute;
    top: -8px;
    left: 7px;
    background: #fff;
    font-size: .875rem;
    font-weight: 300;
    ${({ strColor }) => {
    if (strColor) {
      return `color:${strColor}`
    }
  }}
  }
`