import axios from 'axios';
import ApiHK from './ApiHK';
import storage from '../storage';


import { PasswordChange, UserAuth } from '../@types';

const APIAuth = {
  getHeaderLogin: function (userObj: UserAuth) {
    const userJson = {
      "idusuario": userObj.user,
      "senha": userObj.pass,
      "GrupoEmpresa": userObj.code,
      "licenca": storage.getLicenseToken()
    }
    const formattedJson = JSON.stringify(userJson).trim();
    const token = btoa(formattedJson);

    const header = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    return header;
  },

  
  validEmail: async (email: string) => {
    const body = { email };

    const headers = {
      "Content-Type": "application/json"
    }
    let response: any = {};
    try {

      response = await ApiHK.post('licencaportalcliente', body, { headers });

      if (response.data.status === 0) {
        return response.data;
      } else {
        return { err: true, message: response.data.message }
      }

    } catch (e: any) {
      if (e.response.status) {
        return { err: true, message: "Usuário não encontrado" }
      }
      return { err: true, message: "Ops... Algo deu errado: " + e }

    }

  },

  validLogin: async (obj: UserAuth) => {
    const headers = APIAuth.getHeaderLogin(obj);
    const url = storage.getClientUrl();
    try {
      const response = await axios.get(`${url}/login`, { headers });
      if (response.data.status === 0) {
        return response.data
      }
      return { err: true, message: response.data.mensagem };
    } catch (e) {
      return { err: true, message: e };
    }

  },

  sendPasswordChangeEmail: async (obj: UserAuth) => {
    const headers = APIAuth.getHeaderLogin(obj);
    const url = storage.getClientUrl();

    try {
      const response = await axios.get(`${url}/SolicitarRecuperarSenha`, { headers })
      if (response.data.status === 0) {
        return response.data
      }
      return { err: true, message: response.data.message };
    } catch {
      return { err: true, message: 'Algo deu errado.' };
    }
  },

  changePassword: async (body: PasswordChange, obj: UserAuth) => {
    const headers = APIAuth.getHeaderLogin(obj);
    const url = storage.getClientUrl();

    try {
      const response = await axios.post(`${url}/RecuperarSenha`, body, { headers })
      if (response.data.status === 0) {
        return response.data
      }
      return { err: true, message: response.data.mensagem };
    } catch {
      return { err: true, message: 'Algo deu errado.' };
    }
  },




}

export default APIAuth;