import styled from "styled-components";

type innerPos = "flex-end" | "center" | "flex-start"

interface ISectionProps {
  innerPos?: innerPos
}

export const Container = styled.header`
  grid-area: header;
  width: 100%;
  max-width: 100%;
  display: flex;
  padding: 10px;

  position: relative;
  z-index: 1000;
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  
  justify-content: space-between;    

`

export const SectionHeader = styled.div<ISectionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: ${({ innerPos }) => {
    if (innerPos) {
      return innerPos
    }
    return "center"
  }}

  width: 100%;
  .btn-menu{
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    border-radius: 6px;
    position: relative;   
    transition: all .7s;   
  }

  &.btn-menu:hover{
    background: #eee;
  }

  .btn-menu__hamburger{
    width: 20px;
    height: 2px;
    border-radius: 10px;
    position: relative;
    background: ${props => props.theme.colors.black};
    transition: all .3s;
  }

  .btn-menu__hamburger::after, .btn-menu__hamburger::before{
    position: absolute;
    content: "";
    display:block;
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background: ${props => props.theme.colors.black};
    transition: all .7s;
  }

  .btn-menu__hamburger::after{
    transform: translateY(8px);
    width: 10px;
  }

  .btn-menu__hamburger::before{
    transform: translateY(-8px);
  }

  .btn-menu.open .btn-menu__hamburger{
    transform: translateX(-15px);
    background: transparent;
  }


  .btn-menu.open .btn-menu__hamburger::before{
    transform: rotate(45deg) translate(10px, -10px)
  }

  .btn-menu.open .btn-menu__hamburger::after{
    transform: rotate(-45deg) translate(10px, 10px);
    width: 20px;
  }

  

`

export const MenuButton = styled.div`  
 

`


export const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  border-radius: 6px;
  padding: 10px;
  &:hover{
    background: #eee;
  }
  >span{
    font-size: .875rem;
    padding-right: 10px;
  }

  @media(max-width: 700px){
    display: none;
  }
`

export const Separator = styled.div`
  width: 2px;
  height: 35px;
  background: #eeeef0;
  margin: 0 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
  @media(max-width: 700px){
    display: none;
  }
`