import React from "react";
import Title from "../title";
import { BsFilter } from "react-icons/bs"
import {
  Container,
  Header,
} from "./styles"
import Loading from '../loading';
import { useTheme } from "styled-components";
interface IProps {
  title?: string,
  actionButtons?: {
    icon: JSX.Element;
    action: () => void
  }[];
  filterButton?: boolean;
  handleFilterButton?: () => void;
  loading?: boolean;
  gridArea?: string;
}

const BoxContainer: React.FC<IProps> = ({ children, title, actionButtons, filterButton, handleFilterButton, loading, gridArea }) => {
  const theme = useTheme();

  return (
    <Container gridArea={gridArea}>
      <Header>
        <Title style={{ color: theme.colors.halfOpacityBlack, fontWeight: 300 }}>{title}</Title>
        {
          actionButtons?.map(a => {
            return (
              <>
                <a onClick={a.action} href={"#"}>
                  {a.icon}
                </a>
              </>
            )
          })
        }
        {filterButton && <a onClick={handleFilterButton}><BsFilter size={20} /></a>}
      </Header>

      {!loading ? children : <Loading />}

    </Container>
  )
}
export default BoxContainer;