import React, { useContext, useEffect, useState } from 'react';


import Table from '../../../components/table';
import { MdOpenInNew } from "react-icons/md"
import BoxContainer from '../../../components/boxcontainer';


import moment from "moment"
import { IBodyOcorrencia, IOcorrencia } from '../../../@interfaces';
import Ocorrenciadetalhe from '../ocorrenciadetalhe';
import ModalFilters, { SeparatorFilter, DATE_FORMAT } from '../../../components/modalfilters';
import InputDate from '../../../components/inputdate';
import { MdAttachFile } from "react-icons/md"
import ToastContext from '../../../contexts/toast';
import { CustumerContext } from "../../../contexts/custumer"
import { orderBy } from "lodash"
import { TempStorage } from '../../../storage/temp';
import {
  Container,
  ContentFilters,
  RowSection
} from './styles';
import APIRondas from '../../../services/APIRondas';
import LabelEmptyData from '../../../components/labelemptydata';

import { IStorageFilter } from '../../../@interfaces';


const Ocorrencias: React.FC = () => {
  const columns = [
    { fieldName: "Ocorrência" },
    { fieldName: "Dt. Abertura" },
    { fieldName: "Motivo" },
    { fieldName: "Local" },
    { fieldName: "Ronda" },
    { fieldName: "Ponto de Ronda" },
    { fieldName: "Anexo" },
    { fieldName: "Situação" }
  ]

  const dataInicioPadrao = moment().subtract(1, "month").format(DATE_FORMAT);
  const dataFinalPadrao = moment().format(DATE_FORMAT);

  const [dataRows, setDataRows] = useState<any>([]);
  const [originalData, setOrinalData] = useState<IOcorrencia[]>([]);
  const [currentOcorrencia, setCurrentOcorrencia] = useState<IOcorrencia>({} as IOcorrencia)
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [initDate, setInitDate] = useState<string>(dataInicioPadrao);
  const [finalDate, setFinalDate] = useState<string>(dataFinalPadrao);
  const [valoresFiltrados, setValoresFiltrados] = useState<IStorageFilter>({ dataInicial: dataInicioPadrao, dataFinal: dataFinalPadrao });

  const { id_current_custumer } = useContext(CustumerContext)
  const { showErrorToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);



  useEffect(() => {
    if (id_current_custumer && id_current_custumer > 0) {
      const tempStorage = new TempStorage();
      const storagedFilter = tempStorage.getOcorrenciaFilter();
      if (storagedFilter) {
        const { dataFinal, dataInicial } = storagedFilter;
        setInitDate(dataInicial);
        setFinalDate(dataFinal);
      }
      getData(storagedFilter);
    }
  }, [id_current_custumer]);




  async function getData(objData?: IStorageFilter | null) {
    try {
      setLoading(true);

      setDataRows([]);
      setOrinalData([]);


      const body: IBodyOcorrencia = {
        cliente: id_current_custumer,
        dataInicial: objData ? objData.dataInicial : getDate("ini"),
        dataFinal: objData ? objData.dataFinal : getDate("fim"),
      }


      const response = await APIRondas.getOcorrencias(body);

      setLoading(false);

      if (response.error) {
        showErrorToast(response.message ? response.message : "Erro desconhecido");
        return;
      }

      const list = orderBy(response.listaOcorrencia, ["dataOcorrencia"], ["desc"]).map((item, index) => {
        return {
          ...item,
          id: index + 1
        }
      });
      const rows: any = [];


      if (list && list.length > 0) {
        for (let row of list) {
          let id = null;

          if (row.listaArquivos.length > 0) {
            id = `${<MdAttachFile />} ${row.id}`
          }

          rows.push({
            "id": row.id,
            "Ocorrência": row.ocorrencia,
            "Dt. Abertura": moment(row.dataOcorrencia.replace("Z", "")).format("DD/MM/YYYY HH:mm"),
            "Motivo": row.motivo,
            "Local": row.localServico,
            "Ronda": row.nomeRonda || "--",
            "Ponto de Ronda": row.nomePonto || "--",
            "Situação": row.situacao,
            "Anexo": row.listaArquivos.length > 0 ? <MdAttachFile /> : "--",
            actionButtons: [{
              icon: <MdOpenInNew />,
              indexAction: 0,
            }]
          })
        }


        setDataRows(rows);
        setOrinalData(list);
      }

    } catch (error) {
      setLoading(false);
      throw new Error("GetOcorrências: " + error)
    }
  }


  function openOcorrenciaDetail(id: number) {
    const data: any = originalData.filter(item => item.id === id);
    setCurrentOcorrencia(data[0]);
    setShowDetail(true)
  }



  async function closeDetail() {
    setShowDetail(false);
  }


  function openFilter() {
    setShowFilters(true);
  }


  function closeFilter() {
    setInitDate(valoresFiltrados.dataInicial);
    setFinalDate(valoresFiltrados.dataFinal);
    setShowFilters(false);
  }



  function handleDateIni(e: React.FormEvent<HTMLInputElement>) {
    setInitDate(e.currentTarget.value);
  }

  function handleDateFim(e: React.FormEvent<HTMLInputElement>) {
    setFinalDate(e.currentTarget.value);
  }



  function getDate(type: "ini" | "fim"): string {
    let ini: string, fim: string;

    if (!initDate || !finalDate) {
      const now = moment().format("YYYYMMDD");
      ini = now;
      fim = now;
    } else {
      ini = moment(initDate).format("YYYYMMDD");
      fim = moment(finalDate).format("YYYYMMDD");
    }
    return {
      ini: () => ini,
      fim: () => fim
    }[type]();
  }



  function applyFilter() {
    if (moment(finalDate).isBefore(initDate)) {
      showErrorToast("Data final inferior a inicial");
      return;
    }

    const tempStorage = new TempStorage();

    tempStorage.setOcorrenciaFilter(initDate, finalDate, id_current_custumer);

    setShowFilters(false);
    setValoresFiltrados({ dataInicial: initDate, dataFinal: finalDate });
    getData();
  }

  function cleanFilter() {
    setInitDate(moment().subtract(1, "month").format(DATE_FORMAT));
    setFinalDate(moment().format(DATE_FORMAT))
  }




  return (
    <>

      <Ocorrenciadetalhe ocorrencia={currentOcorrencia} close={closeDetail} show={showDetail} />
      
      {showFilters ? (
        <ModalFilters handleClose={closeFilter} applyFilter={applyFilter} cleanFilter={cleanFilter}>
          <ContentFilters>
            <RowSection>
              <InputDate value={initDate} label="Início" onChange={handleDateIni} />
              <InputDate value={finalDate} label="Fim" onChange={handleDateFim} />
            </RowSection>
            <SeparatorFilter />
          </ContentFilters>
        </ModalFilters>
      ) : null}
      <Container>
        <BoxContainer
          title='Ocorrências'
          filterButton
          handleFilterButton={openFilter}
          loading={loading}
          gridArea={"ocorrencias"}
        >
          <div>
          <span className="dates">{`${moment(valoresFiltrados.dataInicial).format("DD/MM/YYYY")} - ${moment(valoresFiltrados.dataFinal).format("DD/MM/YYYY")}`}</span>
          {dataRows.length === 0 && <LabelEmptyData>Nenhum dado a ser exibido</LabelEmptyData>}
          </div>
          {dataRows.length > 0 && <Table rows={dataRows} columns={columns} actions={[openOcorrenciaDetail]} refAction={"id"} rolagemAzul/>}
        </BoxContainer>
      </Container>
    </>
  )
}

export default Ocorrencias;