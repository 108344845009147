import styled from "styled-components";
import { IoMdClose } from "react-icons/io"


interface IProps {
  color?: string;
  size?: number;
}


export const IconClose = styled(IoMdClose).attrs(props => ({
  color: props.color ? props.color : props.theme.colors.primary,
  size: props.size ? props.size : 18
})) <IProps>`
  cursor: pointer;
`

export const Button = styled.button`
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
`