import React, { createContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IToastContext {
  showSuccessToast: (text: string) => void;
  showErrorToast: (text: string) => void;
}


const ToastContext = createContext<IToastContext>({} as IToastContext);

export const ToastProvider: React.FC = ({ children }) => {
  const [showAlert, setShowAlert] = useState(false);

  
  function showSuccessToast(text: string){
    toast.success(text, { autoClose: 5000 });
    setShowAlert(true);

  }

  function showErrorToast(text: string){
    toast.error(text, { autoClose: 5000 });
    setShowAlert(true);

  }

  return(
    <ToastContext.Provider value={{ showSuccessToast, showErrorToast }}>
      {showAlert && <ToastContainer style={{zIndex: 999999}}/>}
      {children}
    </ToastContext.Provider>
  )
}

export default ToastContext;