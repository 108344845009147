import { BsChevronCompactDown } from "react-icons/bs";
import { Container, Dropdown, Input } from "./styles";
import { ButtonHTMLAttributes, InputHTMLAttributes, ReactNode } from "react";


interface IProps extends InputHTMLAttributes<HTMLInputElement>{
  tipo: "dropdown"|"data"
  children?: ReactNode
  abrirDropdown?: () => void
  erro?: boolean
}


const FiltroInput: React.FC<IProps> = ({ tipo, erro, children, abrirDropdown, ...props }) => {
  
  return tipo === 'dropdown' ? (
    <Dropdown onClick={abrirDropdown} erro={erro}>
      {children}
      <BsChevronCompactDown />
    </Dropdown>
  ):(
    <Input type="date" {...props} erro={erro}/>
  )
}



export default FiltroInput;
