import { CloseIcon, ModalContainer, OptionsContainer, Overlay, OptionButton } from './style';


import { IModalProps } from "../../@interfaces";

const ConfirmationModal: React.FC<IModalProps> = ({ closeModal, message, onActionClick }) => {

  function handleConfirmationClick() {
    if (onActionClick) onActionClick();
    closeModal();
  }

  return (
    <Overlay>
      <ModalContainer>
        <CloseIcon onClick={closeModal} />
        <p>{message}</p>
        <OptionsContainer>
          <OptionButton onClick={handleConfirmationClick}>Sim</OptionButton>
          <OptionButton outline onClick={closeModal}>Cancelar</OptionButton>
        </OptionsContainer>

      </ModalContainer>
    </Overlay>
  )
}

export default ConfirmationModal;