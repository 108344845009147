import axios from "axios";
import aux from '../utils/auxiliar';


const AxiosApp = axios.create({});

AxiosApp.interceptors.request.use(async config => {
  
  const headers = aux.getHeaders();

  config.headers = headers;

  return config
}, error => {
  return Promise.reject(error);
});

export default AxiosApp;