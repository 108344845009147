import styled from "styled-components"


export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background: #F0F2F5;
  padding: 8px 10px;
  border-radius: 4px;
  >span{
    color: rgba(0,0,0,.8);
    font-weight: 500;
  }
  .containerButtons{
    display: flex;
    gap: 18px;
    >button{
      border: none;
      cursor: pointer;
      background:none ;
      color: ${props => props.theme.colors.primary};
      /* 
      width: 25px;
      height: 25px;
      background: yellow;
      border-radius: 25px;
      background: ${props => props.theme.colors.primary}; */
    }
  }
`