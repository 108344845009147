
import React from "react";
import Loader from "react-loader-spinner";
import { useTheme } from "styled-components";
import { Container } from "./styles";

interface IProps {
  type?: "TailSpin" | "Audio" | "BallTriangle" | "Bars" | "Circles" | "Grid" | "Hearts" | "Oval" | "Puff" | "Rings" | "ThreeDots" | "Watch" | "RevolvingDot" | "Triangle" | "Plane" | "MutatingDots" | "CradleLoader";
  color?: string;
  width?: number;
  height?: number;
  noPadding?: boolean;
  compact?: boolean;
}


const Loading: React.FC<IProps> = ({ color, type, width, height, compact, noPadding }) => {
  const theme = useTheme();

  return (
    <Container compact={compact} noPadding={noPadding}>
      {/**@ts-ignore */}
      <Loader type={type ? type : "TailSpin"} color={color ? color : theme.colors.primary} width={width ? width : 30} height={height ? height : 30} />
    </Container>
  )
}

export default Loading;