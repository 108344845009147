import moment from 'moment';
import React from 'react';
import { IFuncionario } from '../../../../@interfaces';

import { Container, NameContainer, UserContainer, Avatar } from './styles';
import aux from '../../../../utils/auxiliar';

interface IProps {
  funcionario: IFuncionario
}

const Card: React.FC<IProps> = ({ funcionario }) => {
  const dtAdmissao = aux.formatRawDateToDate(funcionario.dataAdmissao);
  return (
    <Container>
      <UserContainer>
        <Avatar>
          {<img src={funcionario.avatar ? funcionario.avatar : "assets/user.png"} />}
        </Avatar>
        <NameContainer>
          <span>{funcionario.nome}</span>
          <span id={'empresa'}>{funcionario.empresa}</span>
        </NameContainer>
      </UserContainer>
      <span id="admissao">Data de admissão: <span id='admissaoValue'>{dtAdmissao}</span></span>
    </Container>
  )
}

export default Card;