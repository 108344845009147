import React from "react";
import { IStyle } from "../../@interfaces";

import {
  TitleComponent
} from "./style"



const Title: React.FC<IStyle> = (props) => {
  return <TitleComponent {...props}>{props.children}</TitleComponent>
}

export default Title;