import { Container, Message } from "./styles"


interface IProps {
  texto: string,
  tamanhoFonte?: string
  textoSecundario?: string | null
}


const MensagemListaVazia: React.FC<IProps> = ({ texto, tamanhoFonte, textoSecundario }) => {
  return (
    <Container>
      <span style={{fontSize: tamanhoFonte}}>{texto}</span>
      <img src="assets/empty.png" width={70} style={{ opacity: .6 }} />
      {textoSecundario && <span>{textoSecundario}</span>}
    </Container>
  )
}


export default MensagemListaVazia;