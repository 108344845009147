import React, { useContext } from "react";

import {
  HashRouter,
} from "react-router-dom";

import AuthContext from '../contexts/auth';
import AppRouter from "./app.routes"
import AuthRouter from "./auth.routes"
import CustumerContextProvider from "../contexts/custumer"

const Rotas: React.FC = () => {
  const { isLogged } = useContext(AuthContext);
  return (
    <CustumerContextProvider>
      <HashRouter>
        {isLogged ? <AppRouter /> : <AuthRouter />}
      </HashRouter>
    </CustumerContextProvider>
  )
}

export default Rotas;