import React from 'react';
import { IOpenDocProps } from '../../../../../@interfaces';
import utils from "../../../../../utils/auxiliar"
import { IoMdClose } from "react-icons/io"
import { Container, Header } from './styles';

const OpenDoc: React.FC<IOpenDocProps> = ({ base64, close }) => {
  if (!base64) return null;

  return (
    <Container>
      <Header>
        <button onClick={close}><IoMdClose size={25} /></button>
      </Header>
      <iframe
        title="PDF Viewer"
        src={utils.b64toBlobUrl(base64)}
        width="100%"
        height={'100%'}

      />
    </Container>
  )
}



export default OpenDoc;