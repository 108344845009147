import styled from "styled-components";

interface IProps {
  noPadding?: boolean;
  compact?: boolean;
  height?: number;
}

export const Container = styled.div<IProps>`
  display: flex;
 ${props => !props.compact && `width: 100%;`}
  justify-content: center;
 ${props => !props.compact && `width: 100%;`}
 ${props => !props.noPadding && `padding: 15px;`}
 ${props => props.height && `height: ${props.height}px;`}

`