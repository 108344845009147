import React, { useContext, useEffect, useState } from "react";
import { Fatura } from "../../../@types";
import Pagination from "../../../components/pagination";
import APIFinanceiro from "../../../services/APIFinanceiro";
import aux from "../../../utils/auxiliar";
import CardFatura from "./cardfatura"
import { CompanyFilter, CompanyFilterContainer, Container, DateFilterContainer, FilterContainer, FilterLink, LoadingOverlay } from "./styles";
import { BsFilter } from "react-icons/bs";
import Loading from '../../../components/loading';
import DateFilter from "../components/datefilter";
import ToastContext from "../../../contexts/toast";
import { CustumerContext } from "../../../contexts/custumer";
import { IRows } from "../../../@interfaces";
import DocView from "../../../components/docview";
import EmptyListMessage from "../../../components/mensagemlistavazia"

interface IProps {
  getFile: (arquivoId: number) => Promise<{ base64?: string, errorMessage?: string }>
}

const Faturas: React.FC<IProps> = ({ getFile }) => {
  const [faturas, setFaturas] = useState<Fatura[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBoletoLoading, setIsBoletoLoading] = useState<boolean>(false);
  const [docToShow, setDocToShow] = useState<string>('');

  const defaultStartDate = aux.getAMonthAgoDate();
  const defaultEndDate = aux.getCurrentDate();

  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);

  const [companies, setCompanies] = useState<string[]>();
  const [selectedCompany, setSelectedCompany] = useState<string>();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const { showErrorToast } = useContext(ToastContext);

  const { id_current_custumer } = useContext(CustumerContext);

  useEffect(() => {
    loadFaturas();
  }, [startDate, endDate]);




  useEffect(() => {
    if (startDate === defaultStartDate && endDate === defaultEndDate) loadFaturas();
    else cleanFilter()
  }, [id_current_custumer])




  async function loadFaturas() {
    try {
      setIsLoading(true);
      const response = await APIFinanceiro.getFaturas({ dataInicial: startDate, dataFinal: endDate, cliente: id_current_custumer });

      if (response.faturas) {
        const sortedList = sortFaturas(response.faturas);
        setFaturas(sortedList);
        const distinctCompanies = getDistinctCompanies(sortedList);
        setCompanies(distinctCompanies);
      } else {
        setFaturas([]);
        setSelectedCompany('');
        setCompanies([]);
        // showErrorToast(response.message);
      }

    } catch (e) {
      showErrorToast('Algo deu errado no carregamento das faturas')
    } finally {
      setIsLoading(false);
    }
  }




  function sortFaturas(list: Fatura[]) {
    const sortedList = list.sort((a, b) => {
      const splittedA = a.dtEmissao.split('/', 3);
      const splittedB = b.dtEmissao.split('/', 3);
      const dateA = new Date(parseInt(splittedA[2]), parseInt(splittedA[1]), parseInt(splittedA[0]));
      const dateB = new Date(parseInt(splittedB[2]), parseInt(splittedB[1]), parseInt(splittedB[0]));

      return Number(dateB) - Number(dateA);
    })

    return sortedList;
  }



  function getDistinctCompanies(list: Fatura[]) {
    let companies: string[] = [];
    for (const fatura of list) {
      if (!companies.includes(fatura.descEmpresa)) companies.push(fatura.descEmpresa);
    }

    return companies;
  }



  async function goToNextPage() {
    setCurrentPage(currentPage + 1);
  }




  function goToPrevPage() {
    setCurrentPage(currentPage - 1);
  }




  function openFilter() {
    setIsFilterOpen(true);
  }




  function closeFilter() {
    setIsFilterOpen(false);
  }




  function applyFilter(newStartDate: string, newEndDate: string) {
    setCurrentPage(0);
    setSelectedCompany('');
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    closeFilter();
  }




  function cleanFilter() {
    setSelectedCompany('');
    setCurrentPage(0);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);

    if (startDate === defaultStartDate && endDate === defaultEndDate) loadFaturas();
  }



  function handleShowDoc(base64: string) {
    setDocToShow(base64);
  }


  function closeView() {
    setDocToShow('');
  }



  function handleCompanyFilterClick(company: string) {
    setCurrentPage(0);
    if (company === selectedCompany) setSelectedCompany('');
    else setSelectedCompany(company);
  }


  const linesPerPage = 10
  const start = currentPage * linesPerPage;
  const filteredList = selectedCompany ? faturas.filter(item => item.descEmpresa === selectedCompany) : faturas;
  const dataToShow = filteredList.slice(start, start + linesPerPage);
  const totalOfPages = Math.ceil(filteredList.length / linesPerPage);



  return (
    <Container>
      {isBoletoLoading &&
        <LoadingOverlay>
          <Loading />
        </LoadingOverlay>
      }
      
      {isFilterOpen &&
        <DateFilter
          applyFilter={applyFilter}
          handleClose={closeFilter}
          cleanFilter={cleanFilter}
          currentStartDate={startDate}
          currentEndDate={endDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      }

      {docToShow && <DocView file={docToShow} closeView={closeView}/>}

      <FilterContainer>
        <DateFilterContainer>
          <span>{aux.parseToDDMMYYYY(startDate)} - {aux.parseToDDMMYYYY(endDate)}</span>
          <FilterLink onClick={openFilter}>
            <BsFilter size={20} />
          </FilterLink>
        </DateFilterContainer>

        <CompanyFilterContainer>
          {companies?.length! > 1 && companies?.map(item => (
            <CompanyFilter selected={item === selectedCompany} onClick={() => handleCompanyFilterClick(item)}>
              {item}
            </CompanyFilter>
          ))}


        </CompanyFilterContainer>
      </FilterContainer>


      {!isLoading && dataToShow?.map(item => <CardFatura data={item} getFile={getFile} showDoc={handleShowDoc} key={item.fatura} />)}

      {!isLoading && faturas.length > 0 && <Pagination handleNextPage={goToNextPage} handlePrevPage={goToPrevPage} currentPage={currentPage + 1} totalOfPages={totalOfPages} />}

      {!isLoading && faturas.length === 0 && <EmptyListMessage texto="Não há nenhuma fatura no período escolhido." />}
      {isLoading && <Loading />}
    </Container>
  )
}

export default Faturas;