import styled, { keyframes } from "styled-components";
import CloseButton from "../closebutton";

const animaContent = keyframes`
  from{
    opacity: 0;
    transform: translateY(600px);
  }to{
    opacity: 1;
    transform: translateY(0px);
  }
`


export const Container = styled.div`  
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  flex-direction: column;  
  background-color: ${props => props.theme.colors.white};
  justify-content: flex-start;
  align-items: center;
 
  z-index: 9999;
  padding: 100px;
  border: none;
  animation: ${animaContent} .4s forwards;
  
  > form{
    width: 400px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }

  >span{
    color: ${props => props.theme.colors.red};
    text-align: center;
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 100;
  text-align: center;

  &:after{
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    margin: 15px 0 20px;
    background: ${props => props.theme.colors.silverBG};
  }
`


export const BallOverlay = styled.div`
  z-index: -1; 
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: ${props => props.theme.colors.silverBG};
`

export const PasswordChangeForm = styled.form`
  padding: 20px;
  > div {
    margin: 10px 0;
  }

  > button {
    margin-top: 20px;
  }
`






export const Close = styled(CloseButton).attrs(props => ({
  styles: {
    position: "absolute",
    top: 10,
    right: 10
  },
  color: props.theme.colors.black,
  size: 25
}))``
