import { IFuncionario, IFuncionarioDetalhe } from "../../../../@interfaces";
import aux from "../../../../utils/auxiliar";
import { Container, InfoCell, InfoRow } from "./styles";



interface IGeralProps {
  data: IFuncionarioDetalhe;
}

const Geral: React.FC<IGeralProps> = ({ data }) => {
  return(
    <Container>
      <InfoRow columnTemplate="1fr 3fr 1fr 1fr 1fr">
        <InfoCell>
          <span>RE</span>
          <div>
            <span>
              {data.re}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Nome completo</span>
          <div>
            <span>
              {data.nomeFuncionario}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>CPF</span>
          <div>
            <span>
              {data.cpf}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>RG</span>
          <div>
            <span>
              {data.rg}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Data de Nascimento</span>
          <div>
            <span>
              {aux.formatRawDateToDate(data.dataNascimento)}
            </span>
          </div>
        </InfoCell>
      </InfoRow>
      <InfoRow columnTemplate="3fr 1fr 2fr 2fr 2fr 1fr">
        <InfoCell>
          <span>Endereço</span>
          <div>
            <span>
              {data.endereco.logradouro}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Número</span>
          <div>
            <span>
              {data.endereco.numero}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Complemento</span>
          <div>
            <span>
              {data.endereco.complemento}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Bairro</span>
          <div>
            <span>
              {data.endereco.bairro}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Cidade</span>
          <div>
            <span>
              {data.endereco.cidade}
            </span>
          </div>
        </InfoCell>
        <InfoCell>
          <span>Estado</span>
          <div>
            <span>
              {data.endereco.uf}
            </span>
          </div>
        </InfoCell>
      </InfoRow>
      <InfoRow columnTemplate="2fr 1fr 3fr">
       
        <InfoCell>
          <span>Data de Admissão</span>
          <div>
            <span>
              {aux.formatRawDateToDate(data.dataAdmissao)}
            </span>
          </div>
        </InfoCell>
      </InfoRow>
    </Container>
  )
}

export default Geral;
