import React, { useState } from 'react';
import { Container } from './styles';
import Card from "./card"
import { Ul } from './styles';
import { IDataListaFuncionarios, IFuncionario } from '../../../@interfaces';


const Listafuncionarios: React.FC<IDataListaFuncionarios> = ({ data, openUser }) => {

  if (data.length === 0) {
    return (
      <Container>
        <span>Nenhum funcionário encontrado</span>
      </Container>
    )
  }

  return (
    <Ul>
      {data?.map((i: IFuncionario, index: number) => <li key={index} onClick={() => openUser(i.funcionario)}><Card funcionario={i} /></li>)}
    </Ul>
  );
}

export default Listafuncionarios;