import { useContext, useState } from "react";
import { IFinancialFilterProps } from "../../../../@interfaces";
import InputDate from "../../../../components/inputdate"
import ModalFilters, { ContentFilter, RowSection } from "../../../../components/modalfilters"
import { FilterOption, OptionsContainer, TextInput } from "./styles";
import ToastContext from "../../../../contexts/toast";
import moment from "moment";

const DateFilter: React.FC<IFinancialFilterProps> = ({ currentStartDate, currentEndDate, defaultStartDate, defaultEndDate, applyFilter, cleanFilter, handleClose }) => {
  const [startDate, setStartDate] = useState<string>(currentStartDate);
  const [endDate, setEndDate] = useState<string>(currentEndDate);

  const { showErrorToast } = useContext(ToastContext);


  function handleStartDateChange(event: React.FormEvent<HTMLInputElement>){
    setStartDate(event.currentTarget.value)
  }




  function handleEndDateChange(event: React.FormEvent<HTMLInputElement>){
    setEndDate(event.currentTarget.value);
  }




  function handleApplyFilter(){
    if(moment(endDate).isBefore(startDate)){
      showErrorToast("Data inicial posterior a data final");
      return;
    }
    
    applyFilter(startDate, endDate);
  }




  function handleCleanFilter(){
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    cleanFilter();
  }




  return (
    <ModalFilters applyFilter={handleApplyFilter} cleanFilter={handleCleanFilter} handleClose={handleClose}>
      <ContentFilter>
        {/* <OptionsContainer>
          <FilterOption> */}
            <RowSection>
              <InputDate 
                value={startDate}
                onChange={handleStartDateChange}
                label="Início"
                type={"date"}
                max={endDate}
                inputStyle={{ width: 180 }}
                containerStyle={{ width: 190 }}
              />

              
              <InputDate
                value={endDate}
                onChange={handleEndDateChange}
                label="Fim"
                type={"date"}
                min={startDate}
                inputStyle={{ width: 180 }}
                containerStyle={{ width: 190 }}
              />
            </RowSection>
          {/* </FilterOption>

          <FilterOption>
            <TextInput />
          </FilterOption>
        </OptionsContainer> */}
      
      </ContentFilter>
    </ModalFilters>
  )   
}


export default DateFilter;