import styled from "styled-components"

export const ContentFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  >span{
    font-weight: 400
  }
  >h2{
      margin-bottom: 20px;
      font-weight: 500
    }

  .inputContainer{
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 140px;
    padding: 6px;
    position: relative;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.2);
    margin-right: 10px;
    >input{
      border: none;
      outline: none;
    }
    >label{
      position:absolute;
      top: -8px;
      left: 7px;
      background: #fff;
      font-size: .875rem;
      font-weight: 300;
    }
  }

  

`

export const Section = styled.div`
  display: flex;  
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  
  
  .dateShow{
    font-size: .75rem;
    margin-top: 16px;
  }
`