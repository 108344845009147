import styled from "styled-components";

export const Container = styled.div`  
  display: flex;
  flex-direction: column;
  gap: 30px;
`

interface IInfoRowProps {
  columnTemplate?: string
}

export const InfoRow = styled.div<IInfoRowProps>`
  display: grid;
  grid-template-columns: ${props => props.columnTemplate};
  grid-gap: 20px;
`


export const InfoCell = styled.div`
  color: ${props => props.theme.colors.halfOpacityBlack};
   > span {
    font-size: 1rem;
    font-weight: bold;
   }

   div {
    width: 100%;
    min-height: 30px;
    background: ${props => props.theme.colors.lightGray};
    border: 1px solid #eee;
    padding: 5px;
    margin-top: 10px;
    span {
      font-size: 0.875rem;
    }
   }

`