import React from 'react';
import { AuthContextProvider } from "./contexts/auth";
import { ToastProvider } from "./contexts/toast";
import Rotas from "./routes"
import { GlobalStyle } from './styles/GlobalStyles';

import Defaulttheme from './styles/themes/defaulttheme';
import 'react-loading-skeleton/dist/skeleton.css'

import {
  ThemeProvider
} from 'styled-components'


function App() {
  return (
    <ThemeProvider theme={Defaulttheme}>
      <GlobalStyle />
      <AuthContextProvider>
        <ToastProvider>
          <Rotas />
        </ToastProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
