import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Container, ImgContainer, Header } from './styles';
import { IImageViewProps } from '../../@interfaces';
import { IoMdClose } from "react-icons/io";
import { IoIosDownload } from "react-icons/io";
import utils from "../../utils/auxiliar"




const ImageView: React.FC<IImageViewProps> = ({ imageBase64, close }) => {

  if (!imageBase64) return null;

  return (
    <Container>
      <Header>
        <button onClick={() => utils.downloadFile(imageBase64, `image${Date.now()}.png`)}><IoIosDownload size={25} /></button>
        <button onClick={close}><IoMdClose size={25} /></button>
      </Header>
      <ImgContainer >
        <img src={imageBase64} />
      </ImgContainer>
    </Container>
  )
}

export default ImageView;