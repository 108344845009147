import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "../../../components/table"
import {
  CompanyFilter,
  CompanyFilterContainer,
  Container, FilterContainer, FilterLink, FilterOptionsContainer, FilterSelect, TextInput
} from "./styles"

import { BsDownload, BsFilter, BsEye, BsBoxArrowUpRight } from "react-icons/bs"
import APIFinanceiro from "../../../services/APIFinanceiro";
import { IRows } from "../../../@interfaces";
import { Boleto } from "../../../@types";
import aux from "../../../utils/auxiliar";
import DateFilter from "../components/datefilter";
import Input from "../../../components/input";
import { IoIosSearch } from "react-icons/io"
import Loading from "../../../components/loading";
import ToastContext from "../../../contexts/toast";
import DocView from "../../../components/docview";
import { base64 } from "../../../mocks";
import { CustumerContext } from "../../../contexts/custumer";
import EmptyListMessage from "../../../components/mensagemlistavazia";


const _columns = [
  {
    fieldName: "Empresa"
  },
  {
    fieldName: "NF"
  },

  {
    fieldName: "Val. Bruto",
    alignRight: true
  },
  {
    fieldName: "Val. Liquido",
    alignRight: true
  },
  {
    fieldName: "Banco"
  },
  {
    fieldName: "Emissão"
  },
  {
    fieldName: "Venc. Boleto"
  },
  {
    fieldName: "Val. Boleto",
    alignRight: true
  }
]



interface IBoletosProps {
  getBoletoFile: (arquivoId: number) => Promise<{ base64?: string, errorMessage?: string }>
}


const Boletos: React.FC<IBoletosProps> = ({ getBoletoFile }) => {
  const [dataRow, setDataRow] = useState<IRows[]>([]);


  const defaultStartDate = aux.getAMonthAgoDate();
  const defaultEndDate = aux.getCurrentDate();
  const [startDate, setStartDate] = useState<string>(defaultStartDate);
  const [endDate, setEndDate] = useState<string>(defaultEndDate);
  const [NF, setNF] = useState<string>('');

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>('date');

  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [companies, setCompanies] = useState<string[]>();
  const [selectedCompany, setSelectedCompany] = useState<string>();

  const [docToShow, setDocToShow] = useState<string>('');

  const { showErrorToast } = useContext(ToastContext);

  const { id_current_custumer } = useContext(CustumerContext);


  useEffect(() => {
    loadBoletos();
  }, [])


  useEffect(() => {
    if (!!dataRow) loadBoletos();
  }, [startDate, endDate])




  useEffect(() => {
    if (activeFilter === 'date' && !!dataRow) loadBoletos();
  }, [activeFilter])





  useEffect(() => {
    if (startDate === defaultStartDate && endDate === defaultEndDate) loadBoletos();
    else cleanFilter();
  }, [id_current_custumer])





  async function loadBoletos() {
    try {
      setIsLoading(true);
      const body = activeFilter === 'date' ?
        {
          dataInicial: startDate,
          dataFinal: endDate,
          nf: 0,
          cliente: id_current_custumer
        } : {
          dataInicial: '',
          dataFinal: '',
          nf: parseInt(NF),
          cliente: id_current_custumer
        }

      const response = await APIFinanceiro.getBoletos(body);

      if (response.boletos) {
        const sortedList = sortBoletos(response.boletos);
        const rows = formatRows(sortedList);
        setDataRow(rows);
        const distinctCompanies = getDistinctCompanies(sortedList);
        setCompanies(distinctCompanies);
      } else {
        setDataRow([])
        setCompanies([])
        // showErrorToast(response.message)
      }
    } catch (e) {
      console.log(e);
      showErrorToast('Algo deu errado no carregamento dos boletos')
    } finally {
      setIsLoading(false);
    }
  }




  function formatRows(list: Boleto[]) {
    const rows = list.map(i => {
      return {
        "id": i.contasAReceber,
        "Empresa": i.descEmpresa,
        "NF": i.nF,
        "Val. Bruto": aux.maskReal(i.valBruto),
        "Val. Liquido": aux.maskReal(i.vaLiquido),
        "Banco": i.banco,
        "Emissão": i.dtEmimssao.split(' ')[0],
        "Venc. Boleto": i.vencBoleto,
        "Val. Boleto": aux.maskReal(i.valBoleto),
        timestamp: i.dtEmimssao,
        arquivo: i.arquivo,
        actionButtons: [{
          icon: <BsDownload size={16} />,
          indexAction: 0
        },
        {
          icon: <BsBoxArrowUpRight size={16} />,
          indexAction: 1
        }]

        //  actionButtons: [{
        //   icon: <BsDownload size={16} />,
        //   indexAction: 0
        // }]
      }
    })

    return rows;
  }



  function getDistinctCompanies(list: Boleto[]) {
    let companies: string[] = [];
    for (const boleto of list) {
      if (!companies.includes(boleto.descEmpresa)) companies.push(boleto.descEmpresa);
    }

    return companies;
  }


  function sortBoletos(list: Boleto[]) {
    const sortedList = list.sort((a, b) => {
      const splittedA = a.dtEmimssao.split('/', 3);
      const splittedB = b.dtEmimssao.split('/', 3);
      const dateA = new Date(parseInt(splittedA[2]), parseInt(splittedA[1]), parseInt(splittedA[0]));
      const dateB = new Date(parseInt(splittedB[2]), parseInt(splittedB[1]), parseInt(splittedB[0]));

      return Number(dateB) - Number(dateA);
    })

    return sortedList;
  }


  async function handleBoletoDownload(id: number) {
    const item = dataRow.find(i => i.id === id);
    const file = await getBoletoFile(parseInt(item!.arquivo));
    const name = item!.timestamp;
    const splitted = name.split(" ");
    const formatted = `${aux.parseToYYYYMMDD(splitted[0])} ${splitted[1]}`;

    if (file.base64) aux.downloadFile(file.base64, `${formatted}`);
    else if (file.errorMessage) showErrorToast(file.errorMessage)

  }



  async function viewBoleto(id: number) {
    const item = dataRow.find(i => i.id === id);
    const file = await getBoletoFile(parseInt(item!.arquivo));

    if (file.base64) setDocToShow(file.base64);
    else if (file.errorMessage) showErrorToast(file.errorMessage)

  }



  function openFilter() {
    setIsFilterOpen(true);
  }




  function closeFilter() {
    setIsFilterOpen(false);
  }




  function applyFilter(newStartDate: string, newEndDate: string) {
    setSelectedCompany('');
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    closeFilter();
  }




  function cleanFilter() {
    setSelectedCompany('');
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }




  function handleFilterOptionChange(value: string) {
    setActiveFilter(value);
  }




  function handleSearchByNF() {
    loadBoletos();
  }




  function handleNFChange(value: string) {
    setNF(value.replace(/\D/g, ''));
  }




  function closeView() {
    setDocToShow('');
  }




  function handleCompanyFilterClick(company: string) {
    if (company === selectedCompany) setSelectedCompany('');
    else setSelectedCompany(company);
  }




  return (
    <Container>
      {isFilterOpen &&
        <DateFilter
          applyFilter={applyFilter}
          handleClose={closeFilter}
          cleanFilter={cleanFilter}
          currentStartDate={startDate}
          currentEndDate={endDate}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      }

      {docToShow &&
        <DocView file={docToShow} closeView={closeView} />
      }

      <FilterOptionsContainer>
        <FilterSelect onChange={e => handleFilterOptionChange(e.target.value)}>
          <option value={"date"}>Por Data</option>
          <option value={"nf"}>Nota fiscal</option>
        </FilterSelect>

        <FilterContainer>
          {activeFilter === 'date' ?
            <>
              <span>{aux.parseToDDMMYYYY(startDate)} - {aux.parseToDDMMYYYY(endDate)}</span>
              <FilterLink onClick={openFilter}>
                <BsFilter size={20} />
              </FilterLink>
            </> :
            <>
              <Input placeholder="Digite a NF" value={NF} onChange={e => handleNFChange(e.target.value)} />
              <FilterLink onClick={handleSearchByNF}>
                <IoIosSearch size={20} />
              </FilterLink>
            </>}
        </FilterContainer>

        <CompanyFilterContainer>
          {companies?.length! > 1 && companies?.map(item => (
            <CompanyFilter selected={item === selectedCompany} onClick={() => handleCompanyFilterClick(item)}>
              {item}
            </CompanyFilter>
          ))}
        </CompanyFilterContainer>

      </FilterOptionsContainer>

      {!isLoading && !!dataRow && dataRow!.length > 0 &&
        <Table
          columns={_columns}
          rows={!!selectedCompany ? dataRow.filter(item => item["Empresa"] === selectedCompany) : dataRow}
          refAction="id"
          actions={[handleBoletoDownload, viewBoleto]}
        />
      }

      {!isLoading && !!dataRow && dataRow!.length === 0 &&
        <EmptyListMessage texto={"Não há nenhum boleto com os parâmetros especificados."} />
      }

      {isLoading && <Loading />}

    </Container>
  )
}

export default Boletos;