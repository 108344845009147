import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { IURLTokenProps } from "../../../@interfaces";
import Button from "../../../components/button";
import Input from "../../../components/input";

import ToastContext from "../../../contexts/toast";
import APIAuth from "../../../services/APIAuth";

import { Container, BallOverlay, Title } from "../styles";
import { PasswordChangeForm } from "./styles";

const PasswordChangeModal: React.FC = () => {
  const { userinfo } = useParams<IURLTokenProps>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordRep, setNewPasswordRep] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>();
  const [arePasswordsDifferent, setArePasswordsDifferent] = useState<boolean>();
  const { showErrorToast, showSuccessToast } = useContext(ToastContext);
  const history = useHistory();

  const theme = useTheme()

  async function handleClick() {

    if (!newPassword || !newPasswordRep || !verificationCode) {
      showErrorToast('Preencha todos os campos');
      return;
    }

    if (newPassword !== newPasswordRep) {
      showErrorToast('Senhas divergentes.')
      setArePasswordsDifferent(true);
      return;
    }

    setArePasswordsDifferent(false);

    const body = {
      novasenha: btoa(newPassword),
      confirmacaonovasenha: btoa(newPasswordRep),
      CodigoRecuperacao: btoa(verificationCode)
    };
    const userInfo = JSON.parse(atob(userinfo));
    const user = {
      user: userInfo.email,
      pass: '',
      code: userInfo.code
    }
    const result = await APIAuth.changePassword(body, user);
    if (!result.err) {
      showSuccessToast('Senha trocada com sucesso.')
      history.push('/')
    } else {
      showErrorToast(result.message);
    }
  }




  return (
    <Container>
      <BallOverlay />
      <Title>Troca de senha</Title>
      <PasswordChangeForm onSubmit={e => e.preventDefault()}>
        <Input
          placeholder="Digite o código"
          value={verificationCode}
          onChange={event => setVerificationCode(event.target.value)}
          maxLength={6}
        />
        <Input
          autoComplete="off"
          placeholder="Digite a nova senha"
          type="password"
          value={newPassword}
          colorOfBorder={arePasswordsDifferent ? theme.colors.red : ''}
          onChange={event => setNewPassword(event.target.value)}
        />
        <Input
          autoComplete="off"
          placeholder="Repita a senha"
          type="password"
          value={newPasswordRep}
          colorOfBorder={arePasswordsDifferent ? theme.colors.red : ''}
          onChange={event => setNewPasswordRep(event.target.value)}
        />
        <Button onClick={handleClick}>Confirmar troca</Button>
      </PasswordChangeForm>
    </Container>
  )
}

export default PasswordChangeModal;