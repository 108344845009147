import styled from "styled-components"


export const Container = styled.div`
   overflow-y: auto;  
   display: flex;
   
`

export const Ul = styled.ul`
  max-height: 400px;
  overflow-y: auto;  

  >li{
    margin-bottom: 18px;    
    display: flex;
    align-items: center;
      
    >span{
      width: 10px;
      height: 10px;
      display: block;
      border-radius: 50%;
      background: ${props => props.theme.colors.primary};
      margin-right: 10px;
    }
  }
`


export const ItemDescription = styled.div`  
  display: flex;
  flex-direction: column;
  >h2{
    font-size: .75rem;
    font-weight: 400;
    margin-bottom: 6px
  }
  >h3{
    font-size: .75rem;
    font-weight: 300;
    color: rgba(0,0,0,.7)
  }
`
