import styled, { keyframes } from "styled-components"


const anima = keyframes`
  from{
    transform: scale(0);
  }to{
    transform: scale(1);
    opacity: 1;
  }
`

export const Content = styled.div`
  padding: 20px;
`

export const Container = styled.div`
  overflow-y: auto;
  display: flex;
  flex: 1;  
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%; 
  z-index:7575;  
  transform: translateY(100vh);
  opacity: 0;
  animation: ${anima} .3s forwards;

  #titleOcorrencia{
    color: #fff;
    letter-spacing: 1.2px;
    font-size: 1rem;
    font-weight: 500
  }

  #loading{
    font-size: .875rem;
    color: rgba(0,0,0,.3)
  }
`

export const Title = styled.h2`
  font-size: .875rem;
  color: #000;
  margin-bottom: 8px;
  font-weight: 550;
`

export const Value = styled.span`
  font-size: .875rem;
  font-weight: 350;
  line-height: 24px;
`

export const Separator = styled.div`
  width: 100%;
  height: .2px;
  background: rgba(0,0,0,.1);
  margin: 20px 0 20px;
`


export const SectionTop = styled.div`  
  >div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 300px;
  }  
`


export const Section = styled.div`  
  display: flex;
  flex-direction: column;  
  width: 100%;   
`

export const MediaBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,80px);
  gap: 10px;
 
  >div{
    width: 80px;
    height: 80px;
    border: 1px dotted rgba(0,0,0,.3);
    cursor: pointer;
    >img{
      max-height: 100%;
      max-width: 100%;
    }
  }
  >span{
    font-size: .75rem;
    color:rgba(0,0,0,.3);
  }
`

export const ContainerPath = styled.div`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.colors.darkSilver};
  padding: 10px;
`


