import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { IAgrupamentoDocumento, IDocumento, IEmpresa, ITipoDocumento } from "../../@interfaces";
import { Filtros, SelectOptions, TipoDocumentoOpcoes } from "../../@types";
import { CustumerContext } from "../../contexts/custumer";
import ToastContext from "../../contexts/toast";
import APIArquivos from "../../services/APIArquivos";
import APIDocumentos from "../../services/APIDocumentos";
import APIFuncionario from "../../services/APIFuncionario";
import APIPonto from "../../services/APIPonto";
import { default as Utils, default as aux } from "../../utils/auxiliar";
import { PropsContext } from "./props";

export type OpcoesFiltro = 'tipoDocumento' | 'local' | 'empresa' | 'alocacao' | 'assinatura';

export interface IDocsProps {
	startDate: string;
	finalDate: string;
	employeeId?: number;
	localPrevio?: number;
}
  

export default function useDocs(props: IDocsProps){
  const { startDate, finalDate, employeeId, localPrevio } = props;
	const docFuncionario = !!employeeId && !!localPrevio;

	
  const filtrosValoresIniciais: Filtros = {
    dataInicial: docFuncionario ? startDate : moment(new Date()).format("YYYY-MM"),
    dataFinal: finalDate,
    alocacao: 'tafTodos',
    assinatura: 'tacTodos',
    tipoDocumento: docFuncionario ? null: 0,
    empresa: null,
    local: null
  }

  
  const [filtrosAtuais, setFiltrosAtuais] = useState<Filtros | null>(filtrosValoresIniciais);

  const [filtroAberto, setFiltroAberto] = useState<OpcoesFiltro|null>(null);

  const { colors } = useTheme();
  const [listaDocumento, setListaDocumento] = useState<IDocumento[]>([]);
  const [documentosSelecionados, setDocumentosSelecionados] = useState<number[]>([]);
  const [todosSelecionados, setTodosSelecionados] = useState<boolean>(false);

  const [carregando, setCarregando] = useState<boolean>(false);
  const [acaoCarregando, setAcaoCarregando] = useState<string | null>(null);


  const [tiposDocumento, setTiposDocumento] = useState<TipoDocumentoOpcoes[]>();



  const [documentoAberto, setDocumentoAberto] = useState<string>();
  const [modalDocumentoAberto, setModalDocumentoAberto] = useState<boolean>(false);


  const [empresas, setEmpresas] = useState<SelectOptions[]>([]);
  const [locais, setLocais] = useState<SelectOptions[]>([]);


  const [empresasDisponiveis, setEmpresasDisponiveis] = useState<IEmpresa[]>([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<number>(0);

  const { showErrorToast } = useContext(ToastContext);
  const { id_current_custumer } = useContext(CustumerContext);



  const tiposFixos: TipoDocumentoOpcoes[] = [
    { value: 0, label: "Todos"},
    { value: 1, label: "FGTS", grupoFiltros: 'empresa' },
    { value: 2, label: "GPS", grupoFiltros: 'empresa' },
    { value: 3, label: "Dtcf Web", grupoFiltros: 'empresa' },
    { value: 4, label: "Protocolo FGTS", grupoFiltros: 'empresa' },

    { value: 5, label: "Folha de Pagamento", grupoFiltros: 'cliente' },
    { value: 6, label: "SEFIP", grupoFiltros: 'cliente' },
    { value: 7, label: "Darf", grupoFiltros: 'cliente' },
    { value: 8, label: "GPS Cliente", grupoFiltros: 'cliente' },
  ]


  const alocacoes = [
    {value: "tafTodos", label: "Todos"},
    {value: "tafEfetivo", label: "Efetivo"},
    {value: "tafTemporario", label: "Temporário"},
  ]


  const statusAssinatura = [
    {value: "tacTodos", label: "Todos"},
    {value: "tacAssinado", label: "Assinado"},
    {value: "tacNaoAssinado", label: "Não assinado"},
  ]



  useEffect(() => {
    if (docFuncionario) carregarTiposDocumento();
    else setTiposDocumento(tiposFixos);
  }, [])




  useEffect(() => {
    const novosValores: Filtros = {...filtrosValoresIniciais};
    
    setListaDocumento([]);
    setEmpresasDisponiveis([]);
    setFiltrosAtuais({...novosValores});

    if(id_current_custumer !== 0){
      if(!docFuncionario) setCarregando(true);
      carregarLocais();
      carregarEmpresas();
    }

  }, [id_current_custumer]);



  useEffect(() => {
    if(locais.length > 0 && empresas.length > 0 && !docFuncionario){
      carregarTodosDocumentos();
    } 
  }, [locais, empresas, id_current_custumer]);




  async function carregarDocumentosTodasEmpresas(tipo: TipoDocumentoOpcoes){
    let listaDocumentos: IDocumento[] = [];
   

    try{
    const auxEmpresas = empresas.filter(item => item.value !== "todas")
    for(const empresa of auxEmpresas){
      let filtro: Filtros = { 
        tipoDocumento: parseInt(tipo.value.toString()),
        empresa: parseInt(empresa.value.toString()),
        local: filtrosAtuais?.local!, 
        dataInicial: filtrosAtuais?.dataInicial
      }
        
      const body = gerarBody(filtro);
      const response = await APIDocumentos.getDocumentos(body);
      if(!response.error) {

        let auxResposta = response.documentos?.map(documento => {
          return {...documento, rotuloTipo: tipo.label}
        })

        listaDocumentos = [...listaDocumentos, ...auxResposta!];
      }        


    }
    }catch{

    }

    return listaDocumentos;
  }






  async function carregarTodosDocumentos(){
    setCarregando(true);
    try{
      let listaDocumentos: IDocumento[] = [];
      for(const tipo of tiposFixos){
        let aux = await carregarDocumentosTodasEmpresas(tipo);
        listaDocumentos = [...listaDocumentos, ...aux];
      }

      obterEmpresasDiferentes(listaDocumentos)
      setListaDocumento(listaDocumentos);


    }catch(e){
      console.log(e);
    }finally{
      setCarregando(false);
    }
  }



  function obterEmpresasDiferentes(lista: IDocumento[]){
    let listaEmpresas: IEmpresa[] = []

    for(const documento of lista){
      if(!listaEmpresas.find(empresa => empresa.empresa === documento.relEmpresa)){
        listaEmpresas = [...listaEmpresas, { empresa: documento.relEmpresa, nome: documento.nomeEmpresa}]
      }
    }



    setEmpresasDisponiveis(listaEmpresas);

  }




  async function carregarEmpresas(){
    try{
      const resposta = await APIPonto.getCompanies();
      if (!resposta.error) {
        setEmpresas([...Utils.formatForOptionComponent(resposta.empresas!, 'empresa', 'nome'), { label: 'Todas', value: null}])
      } else {
        // showErrorToast(`Não foi possível carregar as empresas: ${places.mensagem}`);
        return;
      }
    }catch{
      return;
    }

  }



  async function carregarLocais(){
    try{
      const resposta = await APIPonto.getPlacesList({ cliente: id_current_custumer, SomentePontoEletronico: false });

      if (!resposta.error) {
        
        setLocais([...Utils.formatForOptionComponent(resposta.local!, 'local', 'descricao'), {label: "Todos", value: null}]);
      } else {
        
        return;
      }
    }catch{
      return;
    }

  }




  function selecionarEmpresa(empresa: number){
    if(empresa === empresaSelecionada && empresasDisponiveis.length > 1) return setEmpresaSelecionada(0);
    setEmpresaSelecionada(empresa);
  }




  async function carregarTiposDocumento() {
    try {
      const response = await APIFuncionario.getTiposDocumento();

      if (!response.error) {
        const formattedDocTypes = formatarTiposDocumento(response.tiposDocumentos!);
        setTiposDocumento(formattedDocTypes);
      } else {

      }
    } catch {} 
  }




  function formatarTiposDocumento(list: ITipoDocumento[]): SelectOptions[] {
    const formattedList = list.map(item => {
      return { value: item.tipo, label: item.nome }
    })

    return formattedList;
  }




  async function carregarDocumentos() {
    setEmpresasDisponiveis([]);


    if(filtrosAtuais?.tipoDocumento === 0){
      await carregarTodosDocumentos();
      return;
    }

    setCarregando(true);

    const tipoSelecionado = tiposFixos.find(tipo => tipo.value === filtrosAtuais!.tipoDocumento); 

    if(filtrosAtuais?.empresa === null){
      const lista = await carregarDocumentosTodasEmpresas(tipoSelecionado!);
      setEmpresaSelecionada(0);
      setListaDocumento(lista);
      setCarregando(false);
      return;
    }



    try {
      // if(!docFuncionario && !docTypeValue) return;

      setListaDocumento([]);

      const { dataInicial, dataFinal, alocacao, assinatura, tipoDocumento } = filtrosAtuais!;

      const bodies = docFuncionario ? [
        {
          cartaoPonto: {
            funcionario: employeeId,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: localPrevio,
            tipoAlocacao: alocacao,
            tipoAssinatura: assinatura
          }
        },
        {
          demonstrativoPontoCliente: {
            funcionario: employeeId,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: localPrevio,
          }
        }
      ] : gerarBody(filtrosAtuais!);



      const response = docFuncionario ?
        await APIFuncionario.getDocumentos(bodies[tipoDocumento! - 1 as keyof typeof bodies]) :
        await APIDocumentos.getDocumentos(bodies);



      if (!response.error) {
        let auxResposta = response.documentos?.map(documento => {
          return {...documento, rotuloTipo: tiposFixos.find(tipo => tipo.value === filtrosAtuais?.tipoDocumento)?.label}
        })

        setListaDocumento(auxResposta!);
      } 

    } catch {
      showErrorToast("Erro ao carregar a lista de documentos")
    } finally {
      setCarregando(false);

    }
  }



  function gerarBody(filtros: Filtros): any {
    // let tipo;
    let parametros;

    const { tipoDocumento, empresa, local, dataInicial } = filtros!;
    const grupoTipo = tiposFixos.find(item => item.value === tipoDocumento)?.grupoFiltros;
    const data = dataInicial ? dataInicial : new Date();

    if (grupoTipo === 'empresa') {
      parametros = {
        empresa,
        anomes: moment(data).format("YYYYMM")
      }
    } else {
      parametros = {
        empresa,
        anomes: moment(data).format("YYYYMM"),
        local,
        cliente: id_current_custumer
      }
    }

    let label = tiposFixos.find(item => item.value === tipoDocumento)?.label.replace(" de ", "");
    label = label!.replace(" Cliente", "");
    label = label!.replace(/ /g, "");


    const tipoParam = grupoTipo! + label;

    return {
      [tipoParam]: parametros
    }
  }



  function marcarDocumento(id: number, checked: boolean) {
    if (checked) setDocumentosSelecionados([...documentosSelecionados, id]);
    else setDocumentosSelecionados(documentosSelecionados.filter(item => item !== id));

  }




  function marcarTodosDocumentos(checked: boolean) {
    setTodosSelecionados(checked);
    if (checked){
      let selecionados: number[] = [];

      selecionados = listaAMostrar.map(documento => documento.arquivo);
      

      setDocumentosSelecionados(selecionados);
    }
    else setDocumentosSelecionados([]);
  }


  


  async function carregarArquivo(id: number) {
    try {

      const response = await APIArquivos.getArquivo({ Arquivos: [id] })

      if (!response.error) {
        return response.listaArquivo![0]!.conteudo;
      }

      return false;

    } catch {
      return false;
    }
  }




  async function abrirDocumento(id: number) {
    setModalDocumentoAberto(true);
    const file = await carregarArquivo(id);

    if (file) setDocumentoAberto(file);
    else showErrorToast('Erro ao abrir o documento.');
  }




  function fecharDocumento() {
    setModalDocumentoAberto(false);
    setDocumentoAberto('');
  }




  async function baixarArquivo(id: number) {
    setAcaoCarregando(String(id))
    const file = await carregarArquivo(id);

    const fileObj = listaDocumento?.find(item => item.arquivo === id);
    const name = fileObj?.descricaoArquivo;
    if (file) aux.downloadFile(file, `${name}.pdf`);
    else showErrorToast('Erro ao fazer o download do documento.');

    setAcaoCarregando(null);
  }




  async function baixarArquivosSelecionados() {
    setDocumentosSelecionados([]);
    setTodosSelecionados(false);

    for (const docId of documentosSelecionados) {
      await baixarArquivo(docId);
    }

  }




  function atualizarValoresFiltros(value: string | number | null, filter: string){
   
    const novosValores = {...filtrosAtuais!, [filter]: value};
    setFiltrosAtuais({...novosValores});
  }




  function abrirOpcoes(opcao: OpcoesFiltro){
    if(!carregando) setFiltroAberto(opcao);
  }




  function fecharOpcoes(){
    setFiltroAberto(null);
  }




  function agruparLista(lista: IDocumento[]){
    let listaAgrupada: IAgrupamentoDocumento[] = [];

    for(const documento of lista){
      if(!listaAgrupada.find(grupo => grupo.tipo === documento.rotuloTipo)){
        listaAgrupada = [...listaAgrupada, {tipo: documento.rotuloTipo!, documentos: [documento]}]
      }else{
        listaAgrupada = listaAgrupada.map(grupo => {
          return grupo.tipo === documento.rotuloTipo ? 
          {...grupo, documentos: [...grupo.documentos, documento]}
          : grupo
        })
      }
    }

    return listaAgrupada;
  }



  const concantenarNome = tiposFixos.find(item => item.value === filtrosAtuais?.tipoDocumento)?.grupoFiltros === 'cliente';

  const mostrarLista = listaDocumento.length > 0;

  const listaAMostrar = empresaSelecionada ? 
    listaDocumento.filter(documento => documento.relEmpresa === empresaSelecionada) : listaDocumento;
  


  const listaAgrupada: IAgrupamentoDocumento[] = agruparLista(listaAMostrar);

  const tipoSelecionado = !!tiposDocumento ? tiposDocumento!.find(item => item.value === filtrosAtuais!.tipoDocumento): null;


  const opcoes = {
    tipoDocumento: tiposDocumento,
    local: locais,
    empresa: empresas,
    alocacao: alocacoes,
    assinatura: statusAssinatura
  }


  const buscaDisponivel = !docFuncionario || !!filtrosAtuais?.tipoDocumento

  return {
    docFuncionario,
    filtrosAtuais,
    filtroAberto,
    colors,
    todosSelecionados,
    carregando,
    acaoCarregando,
    documentoAberto,
    modalDocumentoAberto,
    empresas,
    locais,
    empresasDisponiveis,
    empresaSelecionada,
    alocacoes,
    statusAssinatura,
    documentosSelecionados,
    tiposDocumento,
    tipoSelecionado,
    concantenarNome,
    mostrarLista,
    listaAgrupada,
    opcoes,
    buscaDisponivel,
    carregarDocumentos,
    selecionarEmpresa,
    abrirDocumento,
    fecharDocumento,
    baixarArquivo,
    baixarArquivosSelecionados,
    atualizarValoresFiltros,
    abrirOpcoes,
    fecharOpcoes,
    marcarDocumento,
    marcarTodosDocumentos,
    
  }
 
}