import React, { useState } from 'react';
import { IDocButtonProps } from '../../../../@interfaces';
import { Container } from './styles';
import { BsDownload, BsFilter, BsEye, BsBoxArrowUpRight } from "react-icons/bs"
import utils from "../../../../utils/auxiliar"
import OpenDoc from './opendoc';

const Doc: React.FC<IDocButtonProps> = ({ name, fileBase64 }) => {
  const [docToOpen, setDocToOpen] = useState<string | null>(null);


  return (
    <>
      <OpenDoc base64={docToOpen} close={() => setDocToOpen(null)} />
      <Container>
        <span>{name}</span>
        <div className='containerButtons'>
          <button onClick={() => setDocToOpen(fileBase64)}><BsBoxArrowUpRight size={16} /></button>
        </div>
      </Container>
    </>
  )
}

export default Doc;