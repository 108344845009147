import styled from "styled-components";

export const PasswordChangeForm = styled.form`
  > div {
    margin: 10px 0;
  }

  > button {
    margin-top: 20px;
  }
`