import React, { useContext } from "react";

import Content from "../content";
import Header from "../header"
import Aside from "../aside";
import MenuContext from "../../contexts/menu";
import AuthContext from "../../contexts/auth";
import PasswordChange from "../passwordchangemodal";
import {
  Container
} from "./styles"

const AppLayout: React.FC = ({ children }) => {
  const { isOpen } = useContext(MenuContext);




  return (
    <Container menuIsOpen={isOpen}>
      <Header />
      <Aside />
      <Content>
        {children}
      </Content>
    </Container>

  )
}

export default AppLayout;