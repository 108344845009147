import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { IDocViewProps } from "../../@interfaces";
import CloseButton from "../closebutton";
import Loading from "../loading";
import { Container, PDFContainer } from "./styles";


const DocView: React.FC<IDocViewProps> = ({ file, closeView }) => {
  const {colors} = useTheme();
	return(
		<Container>
			<CloseButton 
				handleClose={closeView}
				color={'#fff'} 
				size={20}
				styles={{position: 'absolute', top: '5px', right: '5px'}}
			/>

			{file ?
        <PDFContainer>
          <iframe title="pdf" src={typeof file === 'object' ? `./web/viewer.html?file=${window.URL.createObjectURL(file)}`:file} height="100%" width="100%" ></iframe>
        </PDFContainer>:
        <Loading color={colors.white}/>
      }

		</Container>
	)
}


export default DocView;