import styled from "styled-components"

export const MediaBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,80px);
  gap: 10px;
  margin-top: 10px;  
  width: 100%;
  >div{    
    width: 80px;
    height: 80px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
      max-height: 100%;
      max-width: 100%;
    }
  }
  >span{
    font-size: .75rem;
    color:rgba(0,0,0,.3);
  }
`